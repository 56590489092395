import * as React from "react";
import Paragraph from "../../Generic/Paragraph";
import { ReactComponent as ArrowUpIcon } from "../../../Assets/arrowUp.svg";
import { ReactComponent as ArrowDownIcon } from "../../../Assets/arrowDown.svg";
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector";
import { useEffect, useRef, useState } from "react";

interface Props {
  content?: any;
  selectedData?: any;
  setSelectedData?: any;
}

/**
 * TabSelector Component
 */

const TabSelector = ({ content, selectedData, setSelectedData }: Props) => {
  const { isMobile } = useDeviceDetector();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleClick = (
    e,
    parentName: string,
    parentId: string,
    childId: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const obj = {
      parentId: parentId,
      parentName: parentName,
      childId: childId,
      childName: e.target.textContent,
    };
    const selected = selectedData.map((values) => {
      if (values.parentId === parentId) {
        return obj;
      }
      return values;
    });
    setSelectedData(selected);
  };

  const toggleMobileTabs = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    // const parentEle = e.target.closest(".mobileSelectedTab");
    // parentEle.classList.toggle("active");
    // parentEle.nextElementSibling.classList.toggle("active");
  };

  const selectTab = (
    e: any,
    parentName: string,
    parentId: string,
    childId: string
  ) => {
    e.stopPropagation();
    const parentEle = e.target.closest(".tabs");
    const tabSelector = e.target.closest(".mobileTab");
    const mobileSelectedTab = tabSelector.querySelector(".mobileSelectedTab");
    const text = parentEle.children[0].textContent;
    mobileSelectedTab.querySelector(".selected p").innerHTML = text;
    mobileSelectedTab.classList.toggle("active");
    mobileSelectedTab.nextElementSibling.classList.toggle("active");
    const obj = {
      parentId: parentId,
      parentName: parentName,
      childId: childId,
      childName: text,
    };
    const selected = selectedData.map((values) => {
      if (values.parentId === parentId) {
        return obj;
      }
      return values;
    });
    setSelectedData(selected);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="events-tabSelector">
      {content?.childItems.length <= 3 && (
        <div className={`tabHeading`}>
          {!isMobile ? (
            <>
              <Paragraph content={content.parentName} classes="pb-16" />
              <div className="flex">
                {content?.childItems?.map((item, index) => (
                  <div
                    ref={dropdownRef}
                    key={`${item.sys.id}-${index}`}
                    className={`tabs px-24 py-16 cursor-pointer border border-neutral-50 overflow-hidden ${
                      content.childItems.length === 1
                        ? "rounded-lg"
                        : index === 0
                        ? "border-r rounded-tl-lg rounded-bl-lg"
                        : index === content.childItems?.length - 1
                        ? "border-r-none rounded-tr-lg rounded-br-lg"
                        : ""
                    } ${
                      selectedData.filter(
                        (selected) => selected.parentId === content.parentId
                      )[0].childName === item.prefLabel["en-US"]
                        ? "active"
                        : ""
                    }`}
                    onClick={(e) => {
                      toggleClick(
                        e,
                        content.parentName,
                        content.parentId,
                        item.sys.id
                      );
                    }}
                  >
                    <Paragraph content={item.prefLabel["en-US"]} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={`relative mobileTab w-full`} ref={dropdownRef}>
              <Paragraph content={content.parentName} classes="pb-16" />
              <div
                className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden w-full md:min-w-[340px] ${
                  isOpen && "active"
                }`}
                onClick={(e) => {
                  toggleMobileTabs(e);
                }}
              >
                <div
                  className={`selected flex justify-start items-center gap-12 cursor-pointer`}
                >
                  <Paragraph
                    content={
                      selectedData.filter(
                        (selected) => selected.parentId === content.parentId
                      )[0].childName
                    }
                  />
                </div>
                <div className="">
                  <ArrowUpIcon />
                  <ArrowDownIcon />
                </div>
              </div>
              <div
                className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 shadow-xl max-h-[225px] overflow-y-auto ${
                  isOpen && "active"
                }`}
              >
                {content?.childItems?.map((item, index) => (
                  <div
                    key={`${item.sys.id}-${index}`}
                    className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`}
                    onClick={(e) => {
                      selectTab(
                        e,
                        content.parentName,
                        content.parentId,
                        item.sys.id
                      );
                    }}
                  >
                    <Paragraph content={item.prefLabel["en-US"]} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {content?.childItems.length > 3 && (
        <div className={`flex tabHeading`}>
          <div className={`relative mobileTab w-full`} ref={dropdownRef}>
            <Paragraph content={content.parentName} classes="pb-16" />
            <div
              className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden w-full md:min-w-[340px] ${
                isOpen && "active"
              }`}
              onClick={(e) => {
                toggleMobileTabs(e);
              }}
            >
              <div
                className={`selected flex justify-start items-center gap-12 cursor-pointer`}
              >
                <Paragraph
                  content={
                    selectedData.filter(
                      (selected) => selected.parentId === content.parentId
                    )[0].childName
                  }
                />
              </div>
              <div className="">
                <ArrowUpIcon />
                <ArrowDownIcon />
              </div>
            </div>
            <div
              className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 shadow-xl max-h-[225px] overflow-y-auto ${
                isOpen && "active"
              }`}
            >
              {content?.childItems?.map((item, index) => (
                <div
                  key={`${item.sys.id}-${index}`}
                  className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`}
                  onClick={(e) => {
                    selectTab(
                      e,
                      content.parentName,
                      content.parentId,
                      item.sys.id
                    );
                  }}
                >
                  <Paragraph content={item.prefLabel["en-US"]} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabSelector;
