import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import InputComp from "../../Generic/Form/Input"
import TextAreaWithClose from "../../Generic/Form/Textarea"
import DropdownComp from "../../Generic/Form/Dropdown"
import PrimaryButton from "../../Generic/Button/Primary/Button"
import Paragraph from "../../Generic/Paragraph"
import { FormValidations } from "./validations"
import { SaveEventsDetailsAPI, UseVerifyReCaptchaAPI, useContentAPI } from "../../../Hooks/useAPI"
import { TrackGAEvent } from "../../GoogleAnalytics"
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import SuccessBlock from "../SuccessBlock"
import Modal from "../Modal"

const enableReCaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA

interface Props {
    eventData?: any
}

/**
 * Event Registration Form
 * 
 */

const EventRegistration = ({eventData}: Props) => {
    const { data } = useContentAPI('contentModel', 'eventRegistrationForm', "", false)
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { validations } = FormValidations()
    const [invalidFocus, setInvalidFocus] = useState(false)
    const [validationData, setValidationData] = useState(validations)
    const [currentForm, setCurrentForm] = useState<any>(null)
    const [success, setSuccess] = useState(false)
    const [msg, setMsg] = useState("")
    const [start, setStart] = useState(false)
    
    const verifyReCaptcha = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Recaptcha not available')
            return
        }
        const token = await executeRecaptcha('eventRegistrationForm')
        return token
    }, [executeRecaptcha])

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
          window.clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            callback(...args)
          }, wait)
        }
    }

    const validateGuest = (formValues) => {
        if (formValues["additionalGuests"] !== "") {
            const guest = Number(formValues["additionalGuests"]) + 1
            const availableCapacity = eventData.maximumNumberOfAttendees - eventData.totalAttendee

            if (guest > availableCapacity) {
                validationData["additionalGuests"] = {
                    ...validationData["additionalGuests"],
                    error: true,
                    errorMsg: `Unable to register your event, only ${availableCapacity} seats are available.`
                }
                setValidationData({
                    ...validationData
                })
            }
        }
    }

    const validateFields = (name: string, value: string) => {
        const dataField = data?.data?.fields?.filter((item) => item?.id === name)
        let field = validationData[name]
        validationData[name] = {
            ...validationData[name],
            error: false,
            errorMsg: ""
        }

        if ((dataField[0]?.required || field?.required) && value === "") {
            validationData[name] = {
                ...validationData[name],
                error: true,
                errorMsg: validationData[name]?.requiredMsg
            }
        }
        if (value !== "") {
            const regex = field.pattern ? field.pattern : ''
            if(regex && regex !== "") {
                const result = regex.test(value)
                if(!result) {
                    validationData[name] = {
                        ...validationData[name],
                        error: true,
                        errorMsg: validationData[name]?.validationMsg
                    }
                }
            }
        }
        setValidationData({
            ...validationData
        })
    }

    const formatDate = (date: Date) => {
        const day = date.toLocaleString('default', {day: '2-digit'})
        const month = date.toLocaleString('default', {month: '2-digit'})
        const year = date.toLocaleString('default', {year: 'numeric'})
        const time = date.toLocaleString('default', {hour: '2-digit', minute: '2-digit', second: '2-digit'})
        const formattedDate =  `${year}-${month.length === 1 ? '0'+month : month}-${day.length === 1 ? '0'+day : day} ${time.replace(' PM', '')}`
        return formattedDate
    }

    const submitForm = async (formData: any, form: any) => {
        const values = {}
        values["form"] = "event-registration"
        values["EventId"] = eventData.eventId
        values["EventTitle"] = eventData.title
        values['EventDescription'] = eventData.eventDescription
        values["EventDate"] = eventData.eventDate
        values["EventStartDate"] = formatDate(new Date(eventData.startDate))
        values["EventEndDate"] = formatDate(new Date(eventData.endDate))
        values["EventLocation"] = document.getElementsByClassName('evtLocation')[0].textContent
        values["EventCapacity"] = eventData.maximumNumberOfAttendees
        values["EventPresenter1"] = eventData.eventPresenter1 ? eventData.eventPresenter1?.fields?.fullName : ''
        values["EventPresenter2"] = eventData.eventPresenter2 ? eventData.eventPresenter2?.fields?.fullName : ''
        values["EventType"] = eventData.eventLocation

        const today = new Date()
        values["Date Registered"] = formatDate(today)

        for(const d in formData) {
            const filteredValue = data?.data?.fields?.filter((item) => item.id === d)
            if (d === "additionalGuests") {
                if (formData[d] === '') {
                    values[filteredValue[0]?.name] = 0
                } else {
                    values[filteredValue[0]?.name] = formData[d]
                }
            } else {
                values[filteredValue[0]?.name] = formData[d]
            }
        }

        if (enableReCaptcha === "0") {
            const response = await SaveEventsDetailsAPI(values)
    
            if (response.data && response.status === 200) {
                setSuccess(true)
                setMsg(validationData['success'])
                TrackGAEvent('form_submit', 'Event Registration', window.location.pathname)
                form.reset()
            } else {
                setSuccess(false)
                setMsg(validationData['error'])
            }
        } else {
            const reCaptchaToken = await verifyReCaptcha()
            const verifyRecaptcha = await UseVerifyReCaptchaAPI({token : reCaptchaToken})

            if (verifyRecaptcha?.data?.success) {
                const response = await SaveEventsDetailsAPI(values)
        
                if (response.status === 200) {
                    setSuccess(true)
                    setMsg(validationData['success'])
                    TrackGAEvent('form_submit', 'Contact Enquiry', window.location.pathname)
                    form.reset()
                } else {
                    setSuccess(false)
                    setMsg(validationData['error'])
                }
            } else {
                setSuccess(false)
                setMsg(verifyRecaptcha?.data?.msg)
            }
        }
    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setSuccess(false)
        let obj = {}
        const form = e.target.closest('.customForms')
        setCurrentForm(form)
        const formDetails: any = new FormData(form)
        for (const [key, value] of formDetails) {
            obj[key] = value
            validateFields(key, value)
        }
        setInvalidFocus(true)
        validateGuest(obj)
        const success = Object.values(validationData).filter((item) => item.error)
        if(success.length === 0) {
            submitForm(obj, form)
        }
    }

    const onFieldsChange = debounce((name, value) => {
        if (!start) { 
            TrackGAEvent('form_start', 'Event Registration Form', window.location.pathname)
            setStart(true)
        }
        validateFields(name, value)
    }, 1000)

    useEffect(() => {
        if (invalidFocus && currentForm !== null) {
            const errorElement: any = currentForm.querySelector('.invalid')
            errorElement !== null && errorElement.focus()
            setInvalidFocus(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationData, invalidFocus])

    return (<>
        <div className="">
            {!success && 
                <div data-testid="eventform" id="eventform" className="w-100 essForms sectionBottomSpacing formsDiv">
                    <div className="text-right">
                        <Paragraph content="* Mandatory fields" classes="!text-base" />
                    </div>
                    <form className="customForms">
                        {data?.data?.fields?.map((field, index) => (<div key={`${field.id}-${index}`}>
                            {field?.id !== "componentName" && <>
                                {field?.type === 'Array' && 
                                    <DropdownComp 
                                        label={`${field?.name} ${validationData[field?.id]?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        options={field?.items?.validations[0].in}
                                        withCheckBox={false}
                                        required={validationData[field?.id]?.required}
                                        onSelect={(value)=> onFieldsChange(field?.id, value)}
                                        defaultOption={validationData[field?.id]?.placeholder}
                                    />
                                }
                                {field?.type === 'Symbol' && <>
                                    <InputComp
                                        label={`${field?.name} ${validationData[field?.id]?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={validationData[field?.id]?.placeholder}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        variant="text"
                                        required={validationData[field?.id]?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                </>}
                                {field?.type === 'Integer' && 
                                    <InputComp
                                        label={`${field?.name} ${validationData[field?.id]?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={validationData[field?.id]?.placeholder}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={validationData[field?.id]?.helpText}
                                        variant="text"
                                        required={field?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                }
                                {field?.type === 'Text' && 
                                    <TextAreaWithClose
                                        label={`${field?.name} ${validationData[field?.id]?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={validationData[field?.id]?.placeholder}
                                        className=""
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                        maxChar={250}
                                    />
                                }
                                {field?.type === 'Date' && 
                                    <InputComp
                                        label={`${field?.name} ${validationData[field?.id]?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={validationData[field?.id]?.placeholder}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        variant="date"
                                        required={validationData[field?.id]?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                        min={validationData[field?.id]?.min || ""}
                                        max={validationData[field?.id]?.max || ""}
                                    />
                                }
                                </>
                            }
                            </div>
                        ))}
                        {!success && <Paragraph content={msg} classes={`${validationData['success'] === msg ? '!text-success-100' : '!text-danger-100'}`} />}
                        <div className="w-max" onClick={(e) => handleSubmit(e)}>
                            <PrimaryButton variant="dark" name="Submit" buttonType="submit" classes="mt-24" />
                        </div>
                    </form>
                </div>
            }
            {success && <SuccessBlock content={{title: validationData['successTitle'],description: validationData['successMsg']}} />}
            {start && <Modal />}
        </div>
    </>)
}

const EventRegistrationForm = ({eventData}: Props) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            <EventRegistration eventData={eventData} />
        </GoogleReCaptchaProvider>
    )
}


export default EventRegistrationForm