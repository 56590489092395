import * as React from "react";
import { useEffect, useState } from "react";
import Listing from "../Listing";
import { GetTaxonomiesAPI } from "../../../Hooks/useAPI";
import TabSelector from "./TabSelector";
import "./style.css"
import OutlineButton from "../../Generic/Button/Outline/Button";
import Paragraph from "../../Generic/Paragraph";
import Headings from "../../Generic/Heading";

interface Props {
  content?: any;
  parentSlug?: string
}

/**
 * News Component
 * @param content - Content from the CMS.
 */
const NewsBlock = ({
  content,
  parentSlug
}: Props) => {
  const [data, setData] = useState([])
  const [taxonomyData, setTaxonomyData] = useState([])
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([])
  const [unfilteredData, setUnFilteredData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(true)

  const pageSize = 100

  const handlePagination = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setPageNumber(pageNumber + 1)
  }

  const filterData = (concepts: any, array: any) => {
    const filtered = array.map((evt) => {
      if (evt.metadata.concepts) {
        const index = evt.metadata.concepts.findIndex((cpt) => cpt.sys.id === concepts)
        if (index !== -1) {
          return evt
        }
      }
      return null
    }).filter((item) => item !== null)
    return filtered
  }
  
  const renderTaxonomies = async () => {
    if (taxonomyData.length === 0) {
      const taxonomy = await GetTaxonomiesAPI('resource')
      if (taxonomy?.data.length > 0) {
        setTaxonomyData(taxonomy?.data)
      }
    }
  }

  const isIconIncluded = (str) => {
    return str && str.toLowerCase().includes("icon");
  };

  const sortByFilters = (arr) => {
    arr.sort(function(a, b) {
      return a.hiddenLabels['en-US'][0]?.toLowerCase().localeCompare(b.hiddenLabels['en-US'][0]?.toLowerCase());
    });
  } 

  useEffect(() => {
    renderTaxonomies()
    const list = []
    content?.details?.forEach((item) => {
      if (item.fields) {

        const pageBannerContent = item?.fields?.pageContainer[0].fields
        const headlineWithContent = item?.fields?.pageContainer[0].fields?.headlineWithContent[0]?.fields
  
        list.push({
          tag: null,
          iconUrl: isIconIncluded(headlineWithContent?.assets[0]?.fields?.title) || isIconIncluded(headlineWithContent?.assets[0]?.fields?.description) ? headlineWithContent?.assets[0]?.fields?.file?.url : null,
          imageUrl: !isIconIncluded(headlineWithContent?.assets[0]?.fields?.title) && !isIconIncluded(headlineWithContent?.assets[0]?.fields?.description) ? headlineWithContent?.assets[0]?.fields?.file?.url : null,
          headLine: headlineWithContent?.headline ? headlineWithContent?.headline : null,
          contentDescription: pageBannerContent?.description ? pageBannerContent?.description : null,
          contentCtaLinkToPage: item?.fields?.slug ? `${parentSlug}/${item?.fields?.slug}` : null,
          publishDate: pageBannerContent?.publicationDate ? pageBannerContent?.publicationDate : null,
          timeToRead: pageBannerContent?.timeToReadText ? pageBannerContent?.timeToReadText : null,
          videoPodcastUrl: null,
          buzzsproutId: null,
          metadata: item.metadata ? item.metadata : null
        })
      }
    })
    setData(list)
    setUnFilteredData(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!taxonomyData || taxonomyData.length === 0) return

    const filterParent = taxonomyData?.filter((item) => item.broader.length === 0)
    const obj = []
    const selectedObj = []

    sortByFilters(filterParent)

    const filtersSelection = [content?.showResourceFundsFilter ? 'Resource Fund' : '',
    content?.showResourceMediaFilter ? 'Resource Media' : '',
    content?.showResourceTopicsFilter ? 'Resource Topic' : '']

    filterParent.filter((filter) => filtersSelection.includes(filter.definition['en-US'])).forEach((item) => {
      const id = item?.sys?.id
      const name = item?.prefLabel['en-US']
      const filteredValues = taxonomyData?.filter((tax) => tax.broader.findIndex((broad) => broad.sys.id === id) !== -1)
      if (filteredValues.length > 0) {
        filteredValues.unshift({
          "sys": {
            "id": "All",
          },
          "prefLabel": {
            "en-US": "All"
          }
        })
        obj.push({
          parentId: id,
          parentName: name,
          childItems: filteredValues
        })
        selectedObj.push({
          parentId: id,
          parentName: name,
          childId: "All",
          childName: "All"
        })
      }
    })
    setFilters(obj)
    setSelectedFilters(selectedObj)
    setLoading(false)
  }, [taxonomyData])

  useEffect(() => {
    if (!selectedFilters || selectedFilters.length === 0) return

    if (!selectedFilters || selectedFilters.length === 0) return

    if (pageNumber !== 1) {
      setPageNumber(1)
    }

    // Filter other than date filter
    const childConceptIds = selectedFilters.filter((item) => item.parentId !== "date").map((filter) => filter.childId).filter((values) => values !== 'All')
    // const initialFilters = childConceptIds.every((value) => value === 'All')

    let filteredData = []
    
    if (childConceptIds.length > 0) {
      childConceptIds.forEach((concepts, index) => {
        const array = index === 0 ? unfilteredData : filteredData
        const returnedData = filterData(concepts, array)
        if (returnedData.length > 0){
          filteredData = returnedData
        } else {
          filteredData = []
        }
      })
  
    } else {
      filteredData = unfilteredData
    }

    if (filteredData.length > 0) {
      setData(filteredData)
    } else {
      setData([])
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  return (<>
    <div className="sectionBottomSpacing">
      {!loading && <>
        <div className={`layoutSpacing ${content?.addGreyHrLineUnderFilters ? 'border-b border-neutral-50 py-32' : 'pt-24'}`}>
          <div className="w-full layout">
            <div className="flex flex-wrap gap-24">
              {filters?.map((item) => 
                <div key={item.parentId}>
                  <TabSelector content={item} selectedData={selectedFilters} setSelectedData={setSelectedFilters} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="layoutSpacing">
          <div className="w-full layout">
            {data.length > 0 && <>
              <div className="pt-32">
                {data.slice(0, pageSize*pageNumber)?.map((item, index) => 
                  <Listing key={`${item?.headLine}-${index}`} content={item} />
                )}
              </div>
              {(data.length > pageSize*pageNumber) &&
                <div className="flex justify-center pt-32" onClick={(e) => handlePagination(e)}>
                  <OutlineButton name="Load more" variant="dark" buttonType="submit" classes="cursor-pointer" />
                </div>
              }
            </>}
            {data.length === 0 &&
              <div className="pt-32">
                <Paragraph content={'No articles available'} />
              </div>
            }
          </div>
        </div>
      </>}

      {loading &&
        <div className="layoutSpacing">
          <div className="w-full layout">
            <Headings
              variant="H3"
              content="Loading Articles ..."
              classes="text-purple-100"
            />
          </div>
        </div>
      }
    </div>
  </>)
};

export default NewsBlock;
