import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContainerSection from "../Generic/ContainerSection";
import { TrackGAPageView } from "../GoogleAnalytics";
import DisclaimerBlock from "./Disclaimer";
import QuickLinksBlock from "./QuickLinks";
import { useContentAPI } from "../../Hooks/useAPI";
import HeroBanner from "./HeroBanner";
import Header from "./Header";
import Footer from "./Footer";
import Card from "./Card";
import { HelmetProvider } from "react-helmet-async";
import PageBanner from "./PageBanner";
import TextBlock from "./TextBlock";
import FormsAndTransactions from "./FormsAndTransaction";
import Callout from "./Callout";
import SocialMedia from "./SocialMedia";
import FeatureBlock from "./Feature";
import ContactUsForm from "./ContactUsForm";
import Events from "./Events";
import Alert from "./Alert";
import Accordion from "./Accordion";
import CalculatorDetails from "./CalculatorDetails";
import {
  useContentfulLiveUpdates,
  useContentfulInspectorMode,
} from "@contentful/live-preview/react";

import resolveResponse from "contentful-resolve-response";
import ReportSignificantDealingsForm from "./ReportSignificantDealingsForm";
import EventRegistrationForm from "./EventRegistrationForm";
import Tabs from "./Tabs";
import SEOMeta from "./SEOMeta";
import TabSelector from "./Tabs/TabSelector";
import SearchResults from "./SearchResults";
import ChartsContainer from "./ChartsContainer";
import DailyUnitPrice from "./DailyUnitPrice";
import PlannedRetirementForm from "./PlannedRetirement";
import NewsBlock from "./NewsBlock";
import Podcast from "./Podcast";
import IconListBlock from "./IconListBlock";
import Performances from "./Performances";
import TwoColumnArticle from "./TwoColumnArticle";

const BaseTemplate = () => {
  let previewMode = false;
  const [contentData, setContentData] = useState<any>({});
  const [isDisclaimerHaveBorder, setIsDisclaimerHaveBorder] = useState(true);
  const [count, setCount] = useState(0);

  const paths = useParams();
  let slug: any = Object.values(paths).join("/");

  slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.includes("//")
      ? "path-not-correct"
      : slug;

  if (slug.includes("preview/")) {
    previewMode = true;
    slug = slug.replace("preview/", "");
  }
  slug = slug.split("/");
  slug = slug[slug.length - 1];

  const { data } = useContentAPI(
    "entries",
    "destinationPage",
    `${slug}`,
    previewMode
  );

  const previewData = useContentfulLiveUpdates(data);

  const addGATracking = (data: any) => {
    // Track PageView
    TrackGAPageView(data?.fields?.pageTitle);
  };

  const checkSecondLastSection = (sectionsList) => {
    if (sectionsList.length >= 2) {
      const secondLastElem = sectionsList[sectionsList.length - 2];
      if (
        secondLastElem.fields.sectionBackgroundColor &&
        secondLastElem.fields.sectionBackgroundColor !== "White"
      ) {
        setIsDisclaimerHaveBorder(false);
      } else {
        setIsDisclaimerHaveBorder(true);
      }
    }
  };

  const renderData = () => {
    setCount(1);

    if (data && data?.fields?.pageContainer) {
      checkSecondLastSection(data?.fields?.pageContainer);
    }

    if (data && data !== null) {
      const resolver = {
        items: previewMode ? previewData?.data?.items : data?.data?.items,
        includes: previewMode
          ? previewData?.data?.includes
          : data?.data?.includes,
      };
      const response = resolveResponse(resolver);

      if (response[0].fields?.redirectUrl) {
        window.location.href = response[0].fields?.redirectUrl;
      }
      if (response[0].fields?.assetForFactsheets) {
        window.location.href = response[0].fields?.assetForFactsheets?.fields?.file.url;
      }
      setContentData(response[0]);
      addGATracking(response[0]);
    }
  };

  useContentfulInspectorMode();

  useEffect(() => {
    if (!data || data === null) return;
    if (!previewData || previewData === null) return;

    if (count === 0) {
      renderData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMode, data, previewData, count]);

  const toggleTableShadow = () => {
    const tables = document.getElementsByTagName('table')
    for (let i = 0; i < tables.length; i++) {
      const tbody = tables[i].getElementsByTagName('tbody')[0].clientWidth
      const parent = tables[i].parentElement
      const eq = tbody === tables[i].parentElement.clientWidth;
      const hs = tbody > tables[i].parentElement.clientWidth;
      if (eq) {
        tables[i].classList.remove('scroll')
        parent.classList.remove('maxct')
      } else {
        if (hs) {
          tables[i].classList.add('scroll')
          parent.classList.remove('maxct')
        } else {
          tables[i].classList.remove('scroll')
          parent.classList.add('maxct')
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(contentData).length === 0) return
    toggleTableShadow()
    window.addEventListener('resize', toggleTableShadow);
    return () => {
      window.removeEventListener('resize', toggleTableShadow);
    };
  }, [contentData])

  return (
    <HelmetProvider>
      {/* Skip link at the top */}
      <a
        href="#main-content"
        className="absolute -top-10 left-0 focus:top-0 focus:bg-neutral-40 focus:z-50"
      >
        Skip to main content
      </a>
      {contentData && Object.entries(contentData).length > 0 && (
        <ContainerSection>
          {/* Meta Tags Starts */}
          <SEOMeta content={contentData} />
          {/* Meta Tags Ends */}

          {/* Banner Notification Block Starts */}
          {contentData?.fields?.notification && (
            <Alert
              content={contentData?.fields?.notification}
              background={
                contentData?.fields?.notification?.fields?.backgroundColor
              }
            />
          )}
          {/* Banner Notification Block Ends */}

          {/* Header Block Starts */}
          <Header content={contentData?.fields?.header} />
          {/* Header Block Ends */}
          <main id="main-content" className={`${contentData?.fields?.articlePage ? 'layoutArticle' : ''}`}>
            <ContainerSection>
              {slug === "search-results" && <SearchResults />}

              {contentData?.fields?.pageContainer?.map((block, index) => (
                <div key={`${block?.sys?.contentType?.sys?.id}-${index}`}>
                  {/* Hero Banner Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "heroBannerComponent" && (
                    <HeroBanner content={block?.fields} 
                    isArticle={contentData?.fields?.articlePage}/>
                  )}
                  {/* Hero Banner Block Ends */}

                  {/* Contact us form Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "login" && (
                    <ContactUsForm />
                  )}
                  {/* Contact us form Block Ends */}

                  {/* Report Significant Dealings form Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "reportSignificantDealingsForm" && (
                    <ReportSignificantDealingsForm />
                  )}
                  {/* Report Significant Dealings form Block Ends */}

                  {/* Event Registration form Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "eventRegistrationForm" && <EventRegistrationForm />}
                  {/* Event Registration form Block Ends */}

                  {/* Planned Retirement form Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "yourPlannedRetirementDateForm" && <PlannedRetirementForm />}
                  {/* Planned Retirement form Block Ends */}

                  {/* News Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "newsContainer" && (
                    <NewsBlock content={block?.fields} parentSlug={slug} />
                  )}
                  {/* News Block Ends */}

                  {/* Calculators Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "calculators" && (
                    <CalculatorDetails content={block?.fields} />
                  )}
                  {/* Calculators Block Ends */}

                  {/* dailyUnitPrice Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "dailyUnitPrice" && (
                    <DailyUnitPrice content={block?.fields} />
                  )}
                  {/* dailyUnitPrice Block Ends */}

                  {/* Performances Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "performances" && (
                    <Performances content={block?.fields} />
                  )}
                  {/* Hero Banner Block Ends */}

                  {/* Two Columns Article Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "twoColumnsArticleBlock" && (
                    <TwoColumnArticle content={block?.fields} 
                    isArticle={contentData?.fields?.articlePage} />
                  )}
                  {/* Two Columns Article Block Ends */}

                  {/* Page Banner Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "pageBanner" && (
                    <PageBanner
                      content={block?.fields}
                      background={block?.fields.backgroundVariantColor}
                      breadcrumb={contentData?.fields}
                      isArticle={contentData?.fields?.articlePage}
                    />
                  )}
                  {/* Page Banner Block Ends */}

                  {/* Page Alert Starts */}
                  {block?.sys?.contentType?.sys?.id === "notification" && (
                    <Alert
                      content={block}
                      background={block?.fields.backgroundColor}
                    />
                  )}
                  {/* Page Alert Ends */}

                  {/* Rich Text Block Starts */}
                  {block?.sys?.contentType?.sys?.id === "contentComponent" && (
                    <TextBlock content={block?.fields} 
                    isArticle={contentData?.fields?.articlePage} />
                  )}
                  {/* Rich Text Block Ends */}

                  {/* Events Block Ends */}
                  {block?.sys?.contentType?.sys?.id === "eventsBlock" && (
                      <Events content={block?.fields} />
                  )}
                  {/* Events Block Ends */}

                  {/* QuickLinks, Text Block, Forms&Transaction & Cards Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "blockInfoComponent" && (
                    <>
                      {block?.fields?.features ? (
                        <>
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "bunchOfLinksComponents" && (
                            <QuickLinksBlock content={block?.fields} />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "iconList" && (
                            <IconListBlock content={block?.fields}
                            isArticle={contentData?.fields?.articlePage} />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "blockInfoFeatureComponent" && (
                            <>
                              {!block?.fields?.callOut && (
                                <Card
                                  background={
                                    block?.fields?.sectionBackgroundColor
                                  }
                                  cardBackground={
                                    block?.fields?.cardBackgroundColor
                                  }
                                  columnLayout={block?.fields?.cardLayout}
                                  border={block?.fields?.cardBorder}
                                  content={block?.fields}
                                  marginBottom={block?.fields?.componentSpacing} 
                                  isArticle={contentData?.fields?.articlePage}
                                />
                              )}
                              {block?.fields?.callOut && (
                                <Callout
                                  background={
                                    block?.fields?.sectionBackgroundColor
                                  }
                                  content={block?.fields}
                                  textAlignment={block?.fields?.cardTextAlign}
                                  marginBottom={block?.fields?.componentSpacing}
                                />
                              )}
                            </>
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "ctaButtonComponent" && (
                            <TextBlock
                              content={block?.fields}
                              background={block?.fields?.sectionBackgroundColor}
                              marginBottom={block?.fields?.componentSpacing}
                              isArticle={contentData?.fields?.articlePage}
                            />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "pdfCardComponent" && (
                            <FormsAndTransactions content={block?.fields} />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "socialMedia" && (
                            <SocialMedia content={block?.fields} 
                            isArticle={contentData?.fields?.articlePage} />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "tabContainerComponent" && (
                            <>
                              {block?.fields?.componentType === "Tabs" && (
                                <Tabs
                                  content={block?.fields}
                                  border={block?.fields?.cardBorder}
                                  isArticle={contentData?.fields?.articlePage}
                                />
                              )}
                              {block?.fields?.componentType ===
                                "TabSelector" && (
                                <TabSelector
                                  content={block?.fields?.features[0].fields}
                                  border={block?.fields?.cardBorder}
                                />
                              )}
                            </>
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "accordion" && (
                            <Accordion content={block?.fields} />
                          )}
                          {block?.fields?.features[0]?.sys?.contentType?.sys
                            ?.id === "podcast" && (
                            <Podcast content={block?.fields} />
                          )}
                        </>
                      ) : (
                        <>
                          {block?.fields?.assets ? (
                            <>
                              {!block?.fields?.callOut && (
                                <Card
                                  background={
                                    block?.fields?.sectionBackgroundColor
                                  }
                                  cardBackground={
                                    block?.fields?.cardBackgroundColor
                                  }
                                  columnLayout={block?.fields?.cardLayout}
                                  border={block?.fields?.cardBorder}
                                  content={block?.fields}
                                  marginBottom={block?.fields?.componentSpacing} 
                                  isArticle={contentData?.fields?.articlePage}
                                />
                              )}
                              {block?.fields?.callOut && (
                                <Callout
                                  background={
                                    block?.fields?.sectionBackgroundColor
                                  }
                                  content={block?.fields}
                                  textAlignment={block?.fields?.cardTextAlign}
                                  marginBottom={block?.fields?.componentSpacing}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <TextBlock
                                content={block?.fields}
                                background={
                                  block?.fields?.sectionBackgroundColor
                                }
                                marginBottom={block?.fields?.componentSpacing}
                                isArticle={contentData?.fields?.articlePage}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* QuickLinks, Text Block, Forms&Transaction & Cards Block Ends */}

                  {/* Feature Block Starts */}
                  {block?.sys?.contentType?.sys?.id ===
                    "contentWithCtaComponent" && (
                    <>
                      {block?.fields?.variant === "text" && (
                        <FeatureBlock
                          variant="text"
                          background={block?.fields?.backgroundColor}
                          content={block?.fields}
                        />
                      )}
                      {block?.fields?.variant === "appStore" && (
                        <FeatureBlock
                          variant="appStore"
                          background={block?.fields?.backgroundColor}
                          content={block?.fields}
                        />
                      )}
                    </>
                  )}
                  {block?.sys?.contentType?.sys?.id ===
                    "doubleBarreledComponent" && (
                    <>
                      {block?.fields?.columnLayoutVariant ===
                        "Image on the Left Col" && (
                        <FeatureBlock
                          variant="image-left"
                          background={block?.fields?.backgroundColor}
                          content={block?.fields}
                        />
                      )}
                      {block?.fields?.columnLayoutVariant ===
                        "Image on the Right Col" && (
                        <FeatureBlock
                          variant="image-right"
                          background={block?.fields?.backgroundColor}
                          content={block?.fields}
                        />
                      )}
                    </>
                  )}
                  {/* Feature Block Ends */}

                  {/* Charts */}
                  {block?.sys?.contentType?.sys?.id === "charts" && (
                    <>
                      <ChartsContainer content={block?.fields} />
                    </>
                  )}
                </div>
              ))}
            </ContainerSection>
          </main>
          {/* Disclaimer Block Starts */}
            <DisclaimerBlock
              border={isDisclaimerHaveBorder}
              fullWidth={false}
              content={contentData?.fields?.pageContainer}
            />
          {/* Disclaimer Block Ends */}
          {/* Footer Starts */}
          <Footer content={contentData?.fields?.footer} />
          {/* Footer Ends */}
        </ContainerSection>
      )}
    </HelmetProvider>
  );
};

export default BaseTemplate;
