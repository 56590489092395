import * as React from "react"
import { useEffect, useState } from "react"
import Paragraph from "../../Generic/Paragraph"
import Headings from "../../Generic/Heading"
import Tag from "../../Generic/Tag"
import OutlineButtonOnlyIcon from "../../Generic/Button/Outline/IconButton"
import Image from "../../Generic/Image"
import { RenderButton, RenderButtonEvents } from "../../Generic/Button/RenderButton"

interface Props {
    eventsData?: any
    buttonCarousel?: any 
    buttonCards?: any
}

/**
 * Event Cards Carousel Component
 * @param eventsData - Event card data.
 */
const EventCardsCarousel = ({eventsData, buttonCarousel, buttonCards}: Props) => {
    const [value, setValue] = useState(0)
    const [leftArrow, setLeftArrow] = useState(true)
    const [rightArrow, setRightArrow] = useState(false)

    const slideLeft = (e) => {
        e.stopPropagation()
        if (!leftArrow) {
            setRightArrow(false)
            const eventCarousel = e.target.closest('.eventCarousel')
            const carouselList = eventCarousel.querySelector('.carousel')
            // const item = carouselList.querySelectorAll('.carousel-item')
            const widthAddition = (window.innerWidth >= 320 && window.innerWidth < 640) ? (320 + 15) : ((window.innerWidth >= 640 && window.innerWidth < 1199) ? (320 + 15) : 380)
            // const lengthAddition = (window.innerWidth >= 320 && window.innerWidth < 640) ? 1 : ((window.innerWidth >= 640 && window.innerWidth < 1023) ? 2 : 3)
            // const width = (widthAddition) * (item.length - lengthAddition)
            const translate = value - widthAddition
            carouselList.style.transform = `translate3d(-${translate}px, 0px, 0px)`
            setValue(translate)
            if (translate === 0) {
                setLeftArrow(true)
            }
        }
    }

    const slideRight = (e) => {
        e.stopPropagation()
        if (!rightArrow) {
            setLeftArrow(false)
            const eventCarousel = e.target.closest('.eventCarousel')
            const carouselList = eventCarousel.querySelector('.carousel')
            const item = carouselList.querySelectorAll('.carousel-item')
            const widthAddition = (window.innerWidth >= 320 && window.innerWidth < 640) ? (320 + 15) : ((window.innerWidth >= 640 && window.innerWidth < 1199) ? (320 + 15) : 380)
            const lengthAddition = (window.innerWidth >= 320 && window.innerWidth < 640) ? 1 : ((window.innerWidth >= 640 && window.innerWidth < 1023) ? 2 : 3)
            const width = (widthAddition) * (item.length - lengthAddition)
            const translate = value + widthAddition
            carouselList.style.transform = `translate3d(-${translate}px, 0px, 0px)`
            setValue(translate)
            if (translate === width) {
                setRightArrow(true)
            }
        }
    }

    const handleClick = (e, link) => {
        e.stopPropagation()
        window.location.href = link
    }

    const initTransform = () => {
        const carouselList: any = document.querySelector('.carousel')
        const translate = window.innerWidth > 1568 ? ((window.innerWidth - 1440) / 2) - 64 : 0
        carouselList.style.marginLeft = `${translate}px`
        setRightArrow(false)
        setLeftArrow(true)
    }
    useEffect(() => {
        initTransform()
        window.addEventListener('resize', initTransform);
        return () => {
        window.removeEventListener('resize', initTransform);
        };
    }, [])

    return (
        <div className="layoutSpacing eventCarousel w-full py-32 overflow-hidden">
            <div className="carousel flex gap-16 md:gap-24 transition-transform duration-500 ease-in-out" style={{"transform": "translate3d(0px, 0px, 0px)"}}>
                {eventsData?.map((item, index) =>
                    <div key={`${item?.fields?.eventId}-${index}`} className={`carousel-item flex carouselWidth`}>
                        <div className="flex flex-col border border-neutral-50 rounded-2xl overflow-hidden p-32 hover:border-purple-100">
                            <div className="cursor-pointer" onClick={(e) => handleClick(e, `${buttonCards?.fields?.buttonLink}?eventId=${item?.fields?.eventId}`)}>
                                <Headings variant="H4" content={`${item?.fields?.title}`} classes="text-purple-100 pb-16"/>
                            </div>
                            <Paragraph content={item?.fields?.briefDescriptionForEventCards} classes="line-clamp-3" />
                            {item?.fields?.fundTypeValue && 
                                <div className="pt-32">
                                    <Tag name={item?.fields?.fundTypeValue} />
                                </div>
                            }
                            <div className="py-32 grow">
                                {item?.fields?.eventLocation && <div className="flex items-center gap-8 pb-16">
                                    <Image imageUrl={item?.fields?.locationIcon?.fields?.file?.url} altText={item?.fields?.locationIcon?.fields?.file?.title} width="20" />
                                    <Paragraph content={item?.fields?.eventLocation}/>
                                </div>
                                }
                                {item?.fields?.startDate && <div className="flex items-center gap-8">
                                    <Image imageUrl={item?.fields?.eventCalendarIcon?.fields?.file?.url} altText={item?.fields?.eventCalendarIcon?.fields?.file?.title} width="20" />
                                    <Paragraph content={`${new Date(item?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(item?.fields?.startDate).toLocaleDateString('default', {month: 'long'})}`} />
                                </div>
                                }
                            </div>
                            {item?.fields?.capacity ? <RenderButtonEvents item={buttonCards} link={`${buttonCards?.fields?.buttonLink}?eventId=${item?.fields?.eventId}`}  underline={false} />
                            :
                            <Paragraph content="Event capacity is full" classes="!text-danger-100" />
                            }
                        </div>
                    </div>
                )}
                {eventsData?.length > 4 &&
                <div className="carousel-item flex items-center carouselWidth">
                    <RenderButton item={buttonCarousel} />
                </div>
                }
            </div>
            <div className="layout flex flex-wrap justify-end gap-16 sm:gap-24 mt-32">
                <div className="">
                    <RenderButton item={buttonCarousel} />
                </div>
                <div className="navButtons flex gap-8">
                    <div onClick={(e) => {slideLeft(e)}}>
                        <OutlineButtonOnlyIcon variant="dark" buttonType="submit" arrowDirection="left" disabled={leftArrow} />
                    </div>
                    <div onClick={(e) => {slideRight(e)}}>
                        <OutlineButtonOnlyIcon variant="dark" buttonType="submit" disabled={rightArrow} />
                    </div>
                </div>
            </div>
        </div>)
}

export default EventCardsCarousel