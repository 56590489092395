import * as React from "react";
import { ReactComponent as ArrowForwardIcon } from "../../../Assets/arrowForward.svg";
import { ReactComponent as SubMenuForwardIcon } from "../../../Assets/chevron_forward.svg";
import { ReactComponent as SubMenuDownIcon } from "../../../Assets/keyboard_arrow_down.svg";

interface Props {
  name: string;
  link?: string;
  variant: string;
  classes?: string;
  fontColor?: string;
  underline?: boolean;
  display?: boolean;
  arrowDirection?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Text Button Component
 * @param name - Name of the text link.
 * @param link (optional)- Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values:  "LinkArrow" | "Navmobile"
 * @param fontColor (optional) - Color of the text. Expected Tailwindcss classes
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param display (optional) - Position of the icon. Expected values: "true" | "false"
 * @param arrowDirection (optional) - Direction for the arrow. Expected values: "right" | "down"
 */

const navComponent = ({
  name,
  link,
  variant,
  fontColor,
  classes,
  display,
  arrowDirection = "right",
  onClick
}: Props) => {
  return (
    <>
      {variant === "LinkArrow" && (
        <>
          <a
            data-testid="navBarLinkArrow"
            href={link}
            aria-label={name}
            className={`justify-center  text-base md:text-md rounded-lg border-4 border-transparent font-semibold
          ${fontColor ? fontColor : "text-white"}
          ${classes ? classes : ""}
          flex items-start 
          focus:focus:border-4 focus:border-white focus:border-opacity-20 
          active:text-primary-hoverLight
          visited:text-primary-hoverLight`}
          >
            <span className="flex items-start mr-16 ">
              <ArrowForwardIcon />
            </span>
            <span>{name}</span>
          </a>
        </>
      )}
      {variant === "Navmobile" && (
        <div className="flex flex-row w-100 border-b border-b-neutral-50 ">
          <a
            data-testid="navBarmobile"
            href={link}
            aria-label={name}
            className={`text-purple-100 md:text-md font-semibold
          ${fontColor ? fontColor : "text-white"}
          ${classes ? classes : ""}
          flex flex-1 justify-between 
          active:text-primary-hoverLight
          visited:text-primary-hoverLight `}
          >
            <span className="p-24">{name}</span>
          </a>
          <span
            className={`flex items-end p-24 w-72 h-72 bg-purple-10 ${
              display ? "inline" : "hidden"
            }`}
            onClick={onClick}
          >
            {arrowDirection === "right" && <SubMenuForwardIcon />}
          </span>
        </div>
      )}
    </>
  );
};

export default navComponent;
