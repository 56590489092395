const FormValidations = () => {

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["whatIsYourEnquiryAbout"] = {
        id: "whatIsYourEnquiryAbout",
        pattern: "",
        helpText: "",
        required: true,
        placeholder: "Choose an option…",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{3,9}$/,
        helpText: "",
        required: false,
        placeholder: "000000000",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 (maximum of nine characters allowed)"
    }
    validations["fullName"] = {
        id: "fullName",
        pattern: /^[a-zA-Z /-]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "First name and last name",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts alphabets, space and hyphen only (maximum of 100 characters allowed)"
    }
    validations["emailAddress"] = {
        id: "emailAddress",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        placeholder: "email@example.com",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address"
    }
    validations["phoneNumber"] = {
        id: "phoneNumber",
        pattern: /^(?:0\d{9,12}|\+61\d{7,10})$/,
        helpText: "",
        required: false,
        placeholder: "0123456789",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 and '+' (between 10 and 13 characters allowed)"
    }
    validations["yourEnquiry"] = {
        id: "yourEnquiry",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: true,
        placeholder: "Please tell us how we can help",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }

    return { validations }
    
}

export { FormValidations }