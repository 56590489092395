const FormValidations = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const minDate = yyyy + '-' + mm + '-' + dd;

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["distributionOrganisation"] = {
        id: "distributionOrganisation",
        pattern: /^[a-zA-Z0-9 ]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "Name of organisation",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 100 characters allowed)"
    }
    validations["yourName"] = {
        id: "yourName",
        pattern: /^[a-zA-Z /-]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "First name and last name",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts alphabets, space and hyphen (maximum of 100 characters allowed)"
    }
    validations["emailAddressTitle"] = {
        id: "emailAddressTitle",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        placeholder: "email@example.com",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address"
    }
    validations["phoneNumberTitle"] = {
        id: "phoneNumberTitle",
        pattern:  /^(?:0\d{9,12}|\+61\d{7,10})$/,
        helpText: "",
        required: true,
        placeholder: "0123456789",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 and '+' (between 10 and 13 characters allowed)"
    }
    validations["startDateOfTheSignificantDealing"] = {
        id: "startDateOfTheSignificantDealing",
        pattern: "",
        helpText: "",
        required: true,
        placeholder: "",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid date. The date cannot be in the future.",
        format: "date",
        min: "",
        max: minDate
    }
    validations["endDateOfTheSignificantDealing"] = {
        id: "endDateOfTheSignificantDealing",
        pattern: "",
        helpText: "",
        required: false,
        placeholder: "",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "End date should not be older than the start date",
        format: "date",
        min: "",
        max: minDate
    }
    validations["productOfConcern"] = {
        id: "productOfConcern",
        pattern: "",
        helpText: "",
        required: true,
        placeholder: "Select ESSSuper product…",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["descriptionOfTheSignificantDealing"] = {
        id: "descriptionOfTheSignificantDealing",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: true,
        placeholder: "Describe the significant dealing, including why it is not consistent with the TMD.",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }
    validations["whyIsTheDealingSignificant"] = {
        id: "whyIsTheDealingSignificant",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: true,
        placeholder: "Describe why the distributor considered the dealing to be significant.",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }
    validations["howWasTheSignificantDealingIdentified"] = {
        id: "howWasTheSignificantDealingIdentified",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: true,
        placeholder: "For example, the significant dealing may have been identified through reporting from the distributor or as a result of a consumer complaint.",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }
    validations["Textarea"] = {
        id: "Textarea",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: true,
        placeholder: "What steps, if any, have been, or will be, taken in relation to the significant dealing. Include details as relevant.",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }

    return { validations }
    
}

export { FormValidations }