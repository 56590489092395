import * as React from "react";
import { useEffect, useState } from "react";
import { UseDailyUnitPriceAPI } from "../../../Hooks/useAPI";
import Selector from "../../Generic/Selector";
import Paragraph from "../../Generic/Paragraph";
import Headings from "../../Generic/Heading";
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector";
import { ReactComponent as ArrowUpIcon } from "../../../Assets/arrowUp.svg";
import { ReactComponent as ArrowDownIcon } from "../../../Assets/arrowDown.svg";
import "./style.css";
import Chart from "./chart";
import InputComp from "../../Generic/Form/Input";
import TextButton from "../../Generic/Button/Text/Button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

interface Props {
  content: any;
}

const DailyUnitPrice = ({ content }: Props) => {
  const { isMobile } = useDeviceDetector();

  const [loading, setLoading] = useState(true);

  const [responseData, setResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [pdfData, setPdfData] = useState([]);

  const [products, setProducts] = useState([]);
  const [diversified, setDiversified] = useState([]);
  const [assetClass, setAssetClass] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [activeMobileTab, setActiveMobileTab] = useState(false);
  const [selectedDisplayType, setSelectedDisplayType] = useState("Table");
  const [activeDisplayTab, setActiveDisplayTab] = useState(0);
  const [activeMobileDisplayTab, setActiveMobileDisplayTab] = useState(false);

  const [checkSelectAll, setCheckSelectAll] = useState("");

  const [allColumns, setAllColumns] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const assetClasses = [
    { name: "Shares Only", value: false },
    { name: "Cash", value: false },
  ];
  const prods = {
    Pension: "Retirement Income Stream",
    Super: "Accumulation Plan, Beneficiary Account and Working Income Stream",
  };
  const displayType = ["Table", "Chart"];
  const selectAll = ["Select All", "Deselect All"];

  const formatDate = (dateString: Date, append?: string) => {
    const day = dateString.toLocaleString("default", { day: "2-digit" });
    const month = dateString.toLocaleString("default", { month: "2-digit" });
    const year = dateString.toLocaleString("default", { year: "numeric" });
    const formattedDate = `${year}-${
      month.length === 1 ? "0" + month : month
    }-${day.length === 1 ? "0" + day : day}${append}`;
    return formattedDate;
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const tableColumn = pdfData[0].rowHeading;
    tableColumn.unshift('Date')
    const tableRows = pdfData[0].rowValues.map((row) => {
      const rowMap = tableColumn.map((col) => {
        return row[col] || "";
      });
      return rowMap;
    });

    doc.text("Daily Unit Prices", 14, 10);
    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 15,
      headStyles: {
        fillColor: "rgb(232, 245, 246)",
        textColor: "rgb(0, 0, 0)",
        // fontSize: 12,
        halign: "left",
      },
      bodyStyles: {
        fillColor: "rgb(229, 231, 235)",
        textColor: "rgb(66, 66, 66)",
        // fontSize: 12,
        halign: "left",
      },
      alternateRowStyles: {
        fillColor: "rgb(255, 255, 255)",
      },
    });

    doc.save("daily-unit-prices.pdf");
  };

  const toggleTableShadow = () => {
    const tables = document.getElementsByTagName('table')
    for (let i = 0; i < tables.length; i++) {
      const tbody = tables[i].getElementsByTagName('tbody')[0].clientWidth
      const parent = tables[i].parentElement
      const eq = tbody === tables[i].parentElement.clientWidth;
      const hs = tbody > tables[i].parentElement.clientWidth;
      if (eq) {
        tables[i].classList.remove('scroll')
        parent.classList.remove('maxct')
      } else {
        if (hs) {
          tables[i].classList.add('scroll')
          parent.classList.remove('maxct')
        } else {
          tables[i].classList.remove('scroll')
          parent.classList.add('maxct')
        }
      }
    }
  }

  // Generate data based on date selection
  const handleDateRange = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const today = new Date();
    if (value === "Previous 12 months") {
      const last12 = new Date();
      const lastYear = new Date(last12.setMonth(last12.getMonth() - 12));
      const formattedStart = formatDate(lastYear, "");
      const formattedEnd = formatDate(today, "");

      setSelectedStartDate(formattedStart);
      setSelectedEndDate(formattedEnd);
    }
    if (value === "Financial year to date") {
      const lastFinancial = new Date();
      if (lastFinancial.getMonth() + 1 > 4) {
        const finDate = new Date(`04-01-${today.getFullYear()}`);
        const formattedStart = formatDate(finDate, "");
        const formattedEnd = formatDate(today, "");

        setSelectedStartDate(formattedStart);
        setSelectedEndDate(formattedEnd);
      } else {
        const finDate = new Date(`04-01-${today.getFullYear() - 1}`);
        const formattedStart = formatDate(finDate, "");
        const formattedEnd = formatDate(today, "");

        setSelectedStartDate(formattedStart);
        setSelectedEndDate(formattedEnd);
      }
    }
  };

  // Generate default date
  const defaultDateRange = () => {
    const today = new Date();
    let last15Days = new Date();
    last15Days = new Date(last15Days.setDate(last15Days.getDate() - 15));
    const formattedStart = formatDate(last15Days, "");
    const formattedEnd = formatDate(today, "");

    setSelectedStartDate(formattedStart);
    setSelectedEndDate(formattedEnd);
  };

  // DeSelect all Checkboxes
  const deSelectAllCheckboxes = () => {
    const selectAllDiversified = diversified.map((item) => {
      item.value = false;
      return item;
    });
    const selectAllAssetClass = assetClass.map((item) => {
      item.value = false;
      return item;
    });
    setDiversified(selectAllDiversified);
    setAssetClass(selectAllAssetClass);
  };

  // Select all Checkboxes
  const selectAllCheckboxes = () => {
    const selectAllDiversified = diversified.map((item) => {
      item.value = true;
      return item;
    });
    const selectAllAssetClass = assetClass.map((item) => {
      item.value = true;
      return item;
    });
    setDiversified(selectAllDiversified);
    setAssetClass(selectAllAssetClass);
  };

  // DeSelect A Checkbox
  const deSelectACheckbox = (name) => {
    const selectAllDiversified = diversified.map((item) => {
      if (item.name === name) {
        item.value = false;
      }
      return item;
    });
    const selectAllAssetClass = assetClass.map((item) => {
      if (item.name === name) {
        item.value = false;
      }
      return item;
    });
    setDiversified(selectAllDiversified);
    setAssetClass(selectAllAssetClass);
  };

  // Select A Checkbox
  const selectACheckbox = (name) => {
    const selectAllDiversified = diversified.map((item) => {
      if (item.name === name) {
        item.value = true;
      }
      return item;
    });
    const selectAllAssetClass = assetClass.map((item) => {
      if (item.name === name) {
        item.value = true;
      }
      return item;
    });
    setDiversified(selectAllDiversified);
    setAssetClass(selectAllAssetClass);
  };

  // Getting filters selection select all / deselect all
  const handleSelectAll = (checked, value) => {
    if (value === "Select All") {
      setSelectedFilters(allColumns);
      selectAllCheckboxes();
    } else {
      setSelectedFilters([]);
      deSelectAllCheckboxes();
    }
    setCheckSelectAll(value);
  };

  // Getting filters selection
  const handleInvestmentOptions = (checked, value) => {
    if (checked) {
      selectedFilters.push(value);
      setSelectedFilters([...selectedFilters]);
      if (selectedFilters.length === allColumns.length) {
        setCheckSelectAll(selectAll[0]);
        selectAllCheckboxes();
      } else {
        setCheckSelectAll("");
        selectACheckbox(value);
      }
    } else {
      const index = selectedFilters.indexOf(value);
      selectedFilters.splice(index, 1);
      setSelectedFilters([...selectedFilters]);
      if (selectedFilters.length === 0) {
        setCheckSelectAll(selectAll[1]);
        deSelectAllCheckboxes();
      } else {
        setCheckSelectAll("");
        deSelectACheckbox(value);
      }
    }
  };

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };

  // Getting Start Date selection
  const selectStartDate = debounce((name, value) => {
    setSelectedStartDate(value);
  }, 2000);

  // Getting End Date selection
  const selectEndDate = debounce((name, value) => {
    setSelectedEndDate(value);
  }, 2000);

  // Toggle product options
  const toggleProducts = (e, index, value) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTab(index);
    setActiveMobileTab(!activeMobileTab);
    setSelectedProduct(value);
  };

  // Toggle product options for mobile
  const toggleProductsMobile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target;
    target.classList.toggle("active");
    setActiveMobileTab(!activeMobileTab);
  };

  // Selecting product options for Mobile
  const selectProductsMobile = (e, index, value) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTab(index);
    setActiveMobileTab(!activeMobileTab);
    setSelectedProduct(value);
  };

  // Toggle display options
  const toggleDisplayOptions = (e, index, value) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveDisplayTab(index);
    setSelectedDisplayType(value);
  };

  // Toggle display options for mobile
  const toggleDisplayOptionsMobile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target;
    target.classList.toggle("active");
    setActiveMobileDisplayTab(!activeMobileDisplayTab);
  };

  // Selecting display options for Mobile
  const selectDisplayOptionsMobile = (e, index, value) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveDisplayTab(index);
    setActiveMobileDisplayTab(!activeMobileDisplayTab);
    setSelectedDisplayType(value);
  };

  // Serialize the data based on tab and filter selection
  const tableData = () => {
    const dateArr = [];
    let allData = [];
    setErrorMsg("");

    let data = filteredData[selectedProduct];

    if (selectedStartDate !== "" && selectedEndDate !== "") {
      const startDate: any = new Date(selectedStartDate);
      const endDate: any = new Date(selectedEndDate);
      if (selectedEndDate >= selectedStartDate) {
        const noOfDays = Math.floor(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );

        const formattedDate = formatDate(endDate, "T00:00:00.000Z");
        dateArr.push(formattedDate);

        for (let d = 0; d < noOfDays; d++) {
          const nextDate = endDate.setDate(endDate.getDate() - 1);
          const formattedDate = formatDate(
            new Date(nextDate),
            "T00:00:00.000Z"
          );
          dateArr.push(formattedDate);
        }

        const filteredObj = {};
        dateArr?.forEach((item) => {
          if (data[item]) {
            filteredObj[item] = data[item];
          }
        });
        data = filteredObj;
      } else {
        setErrorMsg("End date should not be before start date");
      }
    }

    allData = Object.keys(data).map((obj) => {
      return { ...data[obj], date: obj };
    });

    let headings = [];

    if (selectedFilters.length === 0) {
      allData?.forEach((item) => {
        Object.keys(item).forEach((obj) => {
          if (!headings.includes(obj) && obj !== "date") {
            headings.push(obj);
          }
        });
      });
      setAllColumns(headings);
    } else {
      headings = selectedFilters;
    }

    const values = [];
    allData?.forEach((item) => {
      const filterObj = {};
      headings.forEach((obj) => {
        filterObj[obj] = item[obj];
      });
      const date = new Date(item["date"]);
      const unitDate =
        date.toLocaleDateString("default", { weekday: "long" }) +
        ", " +
        date.toLocaleDateString("default", { day: "numeric" }) +
        " " +
        date.toLocaleDateString("default", { month: "long", year: "numeric" });
      filterObj["Date"] = unitDate;
      values.push(filterObj);
    });
    setDisplayData([{ rowHeading: headings, rowValues: values }]);
    setPdfData([{ rowHeading: headings, rowValues: values }]);
  };

  // Generate/Serialize the data for table, tabs and filters
  const generateTableFiltersAndTabs = () => {
    const productData = [];
    const diversifiedData = [];
    const assetClassData = [];
    let dataObj = [];
    const prodObj = [];
    let previousProd = "";

    responseData?.forEach((item) => {
      if (item?.ResultMessage) {
        const type = item?.InvestmentManager.split(" - ")[1];
        const prod = item?.InvestmentManager.split(" - ")[0];
        const mapProduct = prods[prod];

        if (!productData.includes(mapProduct)) {
          productData.push(mapProduct);
        }

        const typeObj = {
          name: type,
          value: false,
        };

        if (
          diversifiedData.findIndex((item) => item.name === type) === -1 &&
          assetClasses.findIndex((item) => item.name === type) === -1
        ) {
          diversifiedData.push(typeObj);
        }
        if (
          assetClassData.findIndex((item) => item.name === type) === -1 &&
          assetClasses.findIndex((item) => item.name === type) !== -1
        ) {
          assetClassData.push(typeObj);
        }

        const filterObj = {};
        filterObj[type] = item?.UnitPrice;
        if (previousProd !== mapProduct) {
          dataObj = [];
          previousProd = mapProduct;
        }
        dataObj[item?.UnitPriceDate] = {
          ...dataObj[item?.UnitPriceDate],
          ...filterObj,
        };

        prodObj[mapProduct] = { ...prodObj[mapProduct], ...dataObj };
      }
    });

    // const mapProducts = productData?.map((item) => prods[item]).sort()

    setFilteredData(prodObj);
    setProducts(productData.sort());
    setDiversified(diversifiedData);
    setAssetClass(assetClassData);
    setSelectedProduct(productData[0]);
    defaultDateRange();
  };

  // Getting daily unit price data from the API
  const getData = async () => {
    const response = await UseDailyUnitPriceAPI();
    setResponseData(response?.data?.recordset);
  };

  useEffect(() => {
    if (displayData.length === 0) return;
    setLoading(false);
    toggleTableShadow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData]);

  useEffect(() => {
    if (Object.keys(filteredData).length === 0) return;
    tableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredData,
    selectedFilters,
    selectedStartDate,
    selectedEndDate,
    selectedProduct,
  ]);

  useEffect(() => {
    toggleTableShadow()
  }, [loading, activeDisplayTab, activeMobileDisplayTab]);

  useEffect(() => {
    if (responseData.length === 0) return;
    generateTableFiltersAndTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  useEffect(() => {
    getData();
    window.addEventListener("resize", toggleTableShadow);
    return () => {
      window.removeEventListener("resize", toggleTableShadow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="layoutSpacing">
        <div className="w-full layout sectionBottomSpacing dailyUnitPrice">
          {!loading && (
            <div className="flex flex-col gap-24">
              <div className="products flex flex-col gap-24">
                <Headings
                  variant="H3"
                  content={content?.product}
                  classes="text-purple-100 !pb-0"
                />
                <div className="flex flex-wrap gap-24">
                  {!isMobile ? (
                    <div
                      className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}
                    >
                      <div className={`tabHeading flex`}>
                        {products?.map((item, index) => (
                          <div
                            key={`${item}-${index}`}
                            data-item={item}
                            className={`tabs px-24 py-16 cursor-pointer border-r border-neutral-50 overflow-hidden ${
                              activeTab === index ? "active" : ""
                            } ${
                              index === 0
                                ? "border-r rounded-tl-lg rounded-bl-lg"
                                : index === products?.length - 1
                                ? "border-none rounded-tr-lg rounded-br-lg"
                                : ""
                            }`}
                            onClick={(e) => {
                              toggleProducts(e, index, item);
                            }}
                          >
                            <Paragraph content={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={`relative mobileTab w-full`}>
                      <div
                        className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`}
                        onClick={(e) => {
                          toggleProductsMobile(e);
                        }}
                      >
                        <div
                          className={`selected flex justify-start items-center gap-12 cursor-pointer`}
                        >
                          <Paragraph content={products[activeTab]} />
                        </div>
                        <div className="">
                          {activeMobileTab}
                          {activeMobileTab && <ArrowUpIcon />}
                          {!activeMobileTab && <ArrowDownIcon />}
                        </div>
                      </div>

                      <div
                        className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl ${
                          activeMobileTab ? "active" : ""
                        }`}
                      >
                        {products?.map((item, index) => (
                          <div
                            key={`${item}-${index}`}
                            data-item={item}
                            className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`}
                            onClick={(e) => {
                              selectProductsMobile(e, index, item);
                            }}
                          >
                            <Paragraph content={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="timeFrame flex flex-col">
                <Headings
                  variant="H3"
                  content={content?.timeframe}
                  classes="text-purple-100 !pb-24"
                />
                <div className="flex flex-wrap gap-24 items-center">
                  <div className="flex gap-24">
                    <InputComp
                      label="From date"
                      name="fromDate"
                      placeholder="Enter from date"
                      error={false}
                      errorMessage=""
                      variant="date"
                      required={false}
                      className={"!p-0"}
                      onChange={(name, value) => {
                        selectStartDate(name, value);
                      }}
                    />
                    <InputComp
                      label="To date"
                      name="toDate"
                      placeholder="Enter to date"
                      error={false}
                      errorMessage=""
                      variant="date"
                      required={false}
                      className={"!p-0"}
                      onChange={(name, value) => {
                        selectEndDate(name, value);
                      }}
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      handleDateRange(e, "Financial year to date");
                    }}
                  >
                    <TextButton
                      name="Financial year to date"
                      variant="dark"
                      underline={true}
                      link=""
                      buttonType="submit"
                      classes="!text-black"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      handleDateRange(e, "Previous 12 months");
                    }}
                  >
                    <TextButton
                      name="Previous 12 months"
                      variant="dark"
                      underline={true}
                      link=""
                      buttonType="submit"
                      classes="!text-black"
                    />
                  </div>
                </div>
                <Paragraph
                  content={errorMsg}
                  classes={`text-danger-100 !pt-24 ${
                    errorMsg !== "" ? "block" : "hidden"
                  }`}
                />
              </div>
              <div className="investmentOptions flex flex-col gap-24">
                <Headings
                  variant="H3"
                  content={content?.investmentOptions}
                  classes="text-purple-100 !pb-0"
                />
                <div className="flex flex-wrap gap-24 flex-col md:flex-row">
                  {selectAll?.map((item, index) => (
                    <div key={`${item}-${index}`} className="flex flex-wrap">
                      <div className="flex items-center">
                        <Selector
                          type="radio"
                          label={item}
                          value={item}
                          name={item}
                          checked={checkSelectAll === item ? true : false}
                          onChange={(checked, value) =>
                            handleSelectAll(checked, value)
                          }
                          className="!p-0"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Headings
                  variant="H4"
                  content={content?.diversifiedOptions}
                  classes="text-purple-100 !pb-0"
                />
                <div className="flex flex-wrap gap-24">
                  {diversified?.map((item, index) => (
                    <div
                      key={`${item?.name}-${index}`}
                      className="flex flex-wrap w-full md:w-[31%] xl:w-[23%]"
                    >
                      <div className="flex items-center">
                        <Selector
                          type="checkbox"
                          label={item?.name}
                          value={item?.name}
                          name={item?.name}
                          checked={item?.value}
                          onChange={(checked, value) =>
                            handleInvestmentOptions(checked, value)
                          }
                          className="!p-0"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Headings
                  variant="H4"
                  content={content?.singleAssetClassOptions}
                  classes="text-purple-100 !pb-0"
                />
                <div className="flex flex-wrap gap-24">
                  {assetClass?.map((item, index) => (
                    <div
                      key={`${item?.name}-${index}`}
                      className="flex flex-wrap w-full md:w-[31%] xl:w-[23%]"
                    >
                      <div className="flex items-center">
                        <Selector
                          type="checkbox"
                          label={item?.name}
                          value={item?.name}
                          name={item?.name}
                          checked={item?.value}
                          onChange={(checked, value) =>
                            handleInvestmentOptions(checked, value)
                          }
                          className="!p-0"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="displayOptions flex flex-col gap-24">
                <Headings
                  variant="H3"
                  content={content?.displayOption}
                  classes="text-purple-100 !pb-0"
                />
                <div className="flex flex-wrap gap-24">
                  {!isMobile || isMobile ? (
                    <div
                      className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}
                    >
                      <div className={`tabHeading flex`}>
                        {displayType?.map((item, index) => (
                          <div
                            key={`${item}-${index}`}
                            data-item={item}
                            className={`tabs px-24 py-16 cursor-pointer border-r border-neutral-50 overflow-hidden ${
                              activeDisplayTab === index ? "active" : ""
                            } ${
                              index === 0
                                ? "border-r rounded-tl-lg rounded-bl-lg"
                                : index === displayType?.length - 1
                                ? "border-none rounded-tr-lg rounded-br-lg"
                                : ""
                            }`}
                            onClick={(e) => {
                              toggleDisplayOptions(e, index, item);
                            }}
                          >
                            <Paragraph content={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={`relative mobileTab w-full`}>
                      <div
                        className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`}
                        onClick={(e) => {
                          toggleDisplayOptionsMobile(e);
                        }}
                      >
                        <div
                          className={`selected flex justify-start items-center gap-12 cursor-pointer`}
                        >
                          <Paragraph content={displayType[activeDisplayTab]} />
                        </div>
                        <div className="">
                          {activeMobileDisplayTab && <ArrowUpIcon />}
                          {!activeMobileDisplayTab && <ArrowDownIcon />}
                        </div>
                      </div>

                      <div
                        className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl ${
                          activeMobileDisplayTab ? "active" : ""
                        }`}
                      >
                        {displayType?.map((item, index) => (
                          <div
                            key={`${item}-${index}`}
                            data-item={item}
                            className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`}
                            onClick={(e) => {
                              selectDisplayOptionsMobile(e, index, item);
                            }}
                          >
                            <Paragraph content={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="">
                  {displayData[0]?.rowHeading.length === 0 && (
                    <Paragraph content={"No data available"} />
                  )}
                  {selectedDisplayType === "Table" &&
                    displayData[0]?.rowHeading.length > 0 && (
                      <div className={`text-container`}>
                        <div className="tableWrapper">
                          <table>
                            <tbody>
                              <tr>
                                <th>&nbsp;</th>
                                {displayData[0]?.rowHeading?.map((item, index) => (
                                  <th key={`th-${index}`}>{item}</th>
                                ))}
                              </tr>
                              {displayData[0]?.rowValues?.map((item, index) => (
                                <tr key={`tr-${index}`}>
                                  <td>{item["Date"]}</td>
                                  {displayData[0]?.rowHeading.map(
                                    (filter, index) => (
                                      <td key={`tr-${index}`}>{item[filter]}</td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  {selectedDisplayType === "Chart" &&
                    displayData[0]?.rowHeading.length > 0 && (
                      <div className={``}>
                        <Chart content={displayData} />
                      </div>
                    )}
                </div>
              </div>
              {/* <div>
                <button className="dupButton" onClick={generatePDF}>
                  Download the table as a PDF
                  <span className="ml-3">
                    <DownloadIcon />
                  </span>
                </button>
              </div> */}
            </div>
          )}
          {loading && (
            <div className="flex flex-col gap-24">
              <Headings
                variant="H3"
                content="Loading ..."
                classes="text-purple-100"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DailyUnitPrice;
