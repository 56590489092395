const FormValidations = () => {

    let validations = []
    validations["successTitle"] = "Thank you"
    validations["error"] = "Something went wrong. Please try again."
    validations["successMsg"] = "Your booking for our seminar has been registered.<br/><br/> You'll soon receive a confirmation email from the ESSSuper Member Education team with more details.<br/><br/> We look forward to seeing you at the seminar."
    validations["error"] = "Something went wrong. Please try again."
    validations["emailAddress"] = {
        id: "emailAddress",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        placeholder: "email@example.com",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address"
    }
    validations["firstName"] = {
        id: "firstName",
        pattern: /^[a-zA-Z /-]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "First name",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts alphabets, space and hyphen (maximum of 100 characters allowed)"
    }
    validations["lastName"] = {
        id: "lastName",
        pattern: /^[a-zA-Z ]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "Last name",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts alphabets, space and hyphen (maximum of 100 characters allowed)"
    }
    validations["mobileNumber"] = {
        id: "mobileNumber",
        pattern:  /^(?:0\d{9,12}|\+61\d{7,10})$/,
        helpText: "",
        required: true,
        placeholder: "0123456789",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 and '+' (between 10 and 13 characters allowed)"
    }
    validations["dietaryRequirements"] = {
        id: "dietaryRequirements",
        pattern: /^[a-zA-Z0-9 ]{1,100}$/,
        helpText: "",
        required: false,
        placeholder: "Dietary Requirements",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 100 characters allowed)"
    }
    validations["additionalRequirements"] = {
        id: "additionalRequirements",
        pattern: /^[a-zA-Z0-9 ]{1,250}$/,
        helpText: "",
        required: false,
        placeholder: "Additional Requirements",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts text and numbers (maximum of 250 characters allowed)"
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{3,9}$/,
        helpText: "",
        required: true,
        placeholder: "000000000",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 (maximum of nine characters allowed)"
    }
    validations["additionalGuests"] = {
        id: "additionalGuests",
        pattern: /^[0-9]*$/,
        helpText: "",
        required: false,
        placeholder: "Additional guests",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9"
    }

    return { validations }
    
}

export { FormValidations }