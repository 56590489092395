import * as React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useRef } from 'react';
import './style.css'

declare const window: any
/**
 * JobsList Component
 */
const JobsList = () => {
  const meta = useRef(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (meta.current === null) {
      meta.current = "loaded"
      setLoad(true)
    }
  }, [meta])

  useEffect(() => {
    if (!load) return 
    window._baseUrl = "//apps.jobadder.com/widgets";
    window._jaJobsSettings = {
      key: process.env.REACT_APP_JOBADDER_KEY,
      applicationFormSettings: {
        useExternalApplicationForm: true,
        showExternalApplicationFormInNewWindow: false
      },
    };
  }, [load])

  return (<div className="jobAdder py-32">
    {load &&
      <div className="w-full text-container">
        <div id="ja-jobs-widget"></div>
        <Helmet>
          <script src="//apps.jobadder.com/widgets/v1/jobs.min.js"></script>
        </Helmet>
      </div>
    }
    </div>
  );
};

export default JobsList;
