import * as React from "react";
import { ReactComponent as ArrowForwardIcon } from "../../../../../Assets/arrowForward.svg";
import Image from "../../../Image";
import { buttonLinkClick } from "../../RenderButton";

interface Props {
  name: string;
  link: string;
  variant?: string;
  classes?: string;
  iconUrl?: string;
  altText?: string;
  target?: string;
  componentName?: string;
}

/**
 * Card Button Component
 * @param name - Name of the button.
 * @param link - Page to be navigate on click of the button.
 * @param variant (optional) - Variation of the button. Expected values: "" | "stacked"
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param iconUrl (optional) - URL of the icon/image.
 * @param altText (optional) - Alternative text for icon/image.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const CardButton = ({
  name,
  link,
  variant,
  classes,
  iconUrl,
  altText,
  target = "_self",
  componentName,
}: Props) => {
  return (
    <>
      <a
        href={link}
        aria-label={name}
        className={`flex flex-col md:flex-row text-md md:text-lg font-semibold text-purple-100 rounded-2xl bg-white border border-neutral-50
        p-24 w-100 no-underline
        ${
          variant === "stacked"
            ? "md:flex-col items-start"
            : "items-start md:items-center"
        }
        hover:border-purple-100 
        focus:border-4 focus:border-purple-100 focus:border-opacity-20 ${
          classes ? classes : ""
        }`}
        onClick={(e: any) =>
          buttonLinkClick(e, name, link, target, componentName)
        }
        data-testid="cardButton"
      >
        <Image
          imageUrl={iconUrl}
          altText={altText}
          className="max-w-14 max-h-14"
        />
        <span
          className={`flex justify-between items-center w-100 lg:min-h-14 
          ${
            variant === "stacked" ? "pl-0 pt-16" : "pl-0 md:pl-16 pt-16 md:pt-0"
          }`}
        >
          {name}
          <span>
            <ArrowForwardIcon className="ml-12" />
          </span>
        </span>
      </a>
    </>
  );
};

export default CardButton;
