const FormValidations = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const minDate = yyyy + '-' + mm + '-' + dd;

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["consent"] = {
        text: "I consent to providing ESSSuper with my retirement date subject to the <i>Privacy and Data Protection Act 2014</i> for the purposes of providing me with information about retirement and superannuation. For more information about how ESSSuper handles your personal information, please see the ESSSuper <a href='/security-privacy/privacy-policy'>Privacy Policy</a> and <a href='/privacy-collection-statement'>Privacy Collection Statement</a>. *",
        error: true,
        requiredMsg: "This field is mandatory",
        validationMsg: "This field is mandatory",
    }
    validations["haveYouAlreadyRetired"] = {
        id: "haveYouAlreadyRetired",
        pattern: "",
        helpText: "",
        required: true,
        placeholder: "Choose an option…",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "",
        format: "date"
    }
    validations["aimtoretire"] = {
        id: "aimtoretire",
        pattern: "",
        helpText: "",
        required: false,
        placeholder: "dd/mm/yyyy",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid date. The date cannot be in the past.",
        validationMsgFuture: "Invalid date. The date cannot be in the future.",
        format: "date",
        min: minDate,
        max: ""
    }
    validations["ageRetirement"] = {
        id: "ageRetirement",
        pattern: /^[0-9]{1,3}$/,
        helpText: "",
        required: true,
        placeholder: "Retirement age",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9"
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{3,9}$/,
        helpText: "",
        required: false,
        placeholder: "000000000",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 (maximum of nine characters allowed)"
    }
    validations["fullName"] = {
        id: "fullName",
        pattern: /^[a-zA-Z /-]{1,100}$/,
        helpText: "",
        required: true,
        placeholder: "First name and last name",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts alphabets, space and hyphen only, including space and hyphen (maximum of 100 characters allowed)"
    }
    validations["emailAddressTitle"] = {
        id: "emailAddressTitle",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        placeholder: "email@example.com",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address"
    }
    validations["phoneNumber"] = {
        id: "phoneNumber",
        pattern: /^(?:0\d{9,12}|\+61\d{7,10})$/,
        helpText: "",
        required: false,
        placeholder: "0123456789",
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts numbers 0-9 and '+' (between 10 and 13 characters allowed)"
    }

    return { validations }
    
}

export { FormValidations }