import * as React from "react";
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import "./style.css";
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector";

interface Props {
  content: any;
  isArticle?: boolean
}
/**
 * Hero Banner Component
 * @param content - Content from the CMS.
 * @param isArticle (optional) - To set article page layout
 */

const HeroBanner = ({ content, isArticle }: Props) => {
    const { isMobile, isTablet } = useDeviceDetector()
  return (<>
    {!isMobile && !isTablet && !isArticle && <div data-testid="heroBanner" className="heroBanner relative w-full layoutSpacing sectionSpacing sectionBottomMargin min-h-[512px] 2xl:min-h-[600px]" style={{background: `url(${content?.bannerImage?.fields?.file?.url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right center'}}>
      <div className="imageBlur"></div>
      <div className="relative z-10 flex items-center layout py-16">
        <div className="flex flex-col lg:w-[48%] 2xl:w-[43%]">
          <Headings
            variant="H1"
            content={content?.headline}
            classes="text-white !pb-40"
          />
          {content?.subHeadline && <Paragraph classes="text-white !pb-40" content={content?.subHeadline} />}
          {content?.bodyText && <Paragraph classes="!text-xl text-white !pb-40" content={content?.bodyText} />}
          <div>
            <PrimaryButtonWithIcon
              variant="light"
              name={content?.ctaText}
              link={content?.targetPage?.fields?.slug}
              buttonType="button"
            />  
          </div>
        </div>
      </div>
    </div>}
    {(isMobile || isTablet || isArticle) && <div data-testid="heroBanner" className="relative w-full">
      <img
        src={content?.bannerImage?.fields?.file?.url}
        alt={content?.bannerImage?.fields?.title}
        className="w-full object-cover min-h-[400px]"
        role="presentation"
      />
      <div className="sectionBottomSpacing">
        <div className="relative z-10 flex items-center layout layoutSpacing pb-48 bannerTextMobile">
          <div className="imageBlurMobile"></div>
          <div className="relative flex flex-col z-10">
            <Headings
              variant="H1"
              content={content?.headline}
              classes="text-white !pb-40 !text-40"
            />
            {content?.subHeadline && <Paragraph classes="text-white !pb-16" content={content?.subHeadline} />}
            {content?.bodyText && <Paragraph classes="text-white !pb-16 !text-lg" content={content?.bodyText} />}
            <div>
              <PrimaryButtonWithIcon
                variant="light"
                name={content?.ctaText}
                link={content?.targetPage?.fields?.slug}
                buttonType="button"
              />  
            </div>
          </div>
        </div>
      </div>
    </div>}
  </>
  );
};
export default HeroBanner;
