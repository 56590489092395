import * as React from "react";
import Paragraph from "../../Generic/Paragraph"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"

interface Props {
    content?: any
    selectedData?: any
    setSelectedData?: any
}

/**
 * TabSelector Component
 */

const TabSelector = ({
    content,
    selectedData,
    setSelectedData
}: Props) => {
      const { isMobile } = useDeviceDetector()

    const toggleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedData(e.target.textContent)
    }

    const toggleMobileTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.mobileSelectedTab')
        parentEle.classList.toggle('active')
        parentEle.nextElementSibling.classList.toggle('active')
    }

    const selectTab = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const tabSelector = e.target.closest('.mobileTab')
        const mobileSelectedTab = tabSelector.querySelector('.mobileSelectedTab')
        const text = parentEle.children[0].textContent
        mobileSelectedTab.querySelector('.selected p').innerHTML = text
        mobileSelectedTab.classList.toggle('active')
        mobileSelectedTab.nextElementSibling.classList.toggle('active')
        setSelectedData(text)
    }
  return (
    <div className="tabSelector">
        <div className={`flex tabHeading`}>
                {!isMobile ? <>
                    {content?.map((item, index) =>
                        <div key={`${item}-${index}`} className= {`tabs px-24 py-16 cursor-pointer border border-neutral-50 overflow-hidden ${content.length === 1 ? 'rounded-lg' : index === 0 ? 'border-r rounded-tl-lg rounded-bl-lg' : index === content?.length - 1 ? 'border-r-none rounded-tr-lg rounded-br-lg' : ''} ${selectedData === item ? 'active' : ''}`} onClick={(e) => {toggleClick(e)}}>
                        <Paragraph content={item} />
                        </div>
                    )}
                </>
                :
                    <div className={`relative mobileTab w-full`}>
                        <div className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`} onClick={(e) => {toggleMobileTabs(e)}}>
                            <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                <Paragraph content={selectedData} />
                            </div>
                            <div className="">
                                <ArrowUpIcon />
                                <ArrowDownIcon />
                            </div>
                        </div>
                        <div className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl`}>
                            {content?.map((item, index) => <>
                                <div key={`${item}-${index}`} className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                    <Paragraph content={item} />
                                </div>
                            </>)}
                        </div>
                    </div>
                }
        </div>
    </div>
  );
};

export default TabSelector;
