import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { RenderButton } from "../../Generic/Button/RenderButton";
import { ReactComponent as AppleIcon } from "../../../Assets/apple.svg";
import { ReactComponent as GoogleIcon } from "../../../Assets/google.svg";
import Image from "../../Generic/Image";

interface Props {
    variant: string
    background?: string
    content?: any
}

/**
 * Feature Button Component
 * @param variant - Variant of the component. Expected values: "text" | "appStore" | "image-left" | "image-right"
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" | "teal", Default: "white"
 */
const FeatureBlock = ({variant, content, background = 'purple'}: Props) => {
    background = background.toLowerCase()
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    const textColor = background === "purple" ? "text-white" : background === "orange" ? "text-black" : background === "teal" ? "text-black" : background === "lightpurple" ? "text-black" : "text-black"

    const isIconIncluded = (str) => {
        return str && str.toLowerCase().includes("icon");
      };
    return (<>
        <div className="layoutSpacing">
            <div data-testid="FeatureBlock" className={`w-100 layout sectionBottomSpacing`}>
                {variant === 'text' &&
                    <div data-testid="featureText" className={`flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 rounded-2xl border border-neutral-50 ${bgColor}`}>
                        <div className="flex-1 pr-16">
                            <Headings  content={content?.headline} variant="H3" classes={`${textColor}`} />
                            <Paragraph content={content?.bodyText} classes={`${textColor}`} />
                        </div>
                        <div className="flex flex-col flex-wrap gap-16" data-testid="buttonComponent">
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'appStore' &&
                    <div data-testid="featureAppStore" className={`flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 rounded-2xl border border-neutral-50 ${bgColor}`}>
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes={`${textColor}`} />
                        </div>
                        <div className="flex flex-wrap gap-8" data-testid="ctaButton">
                            {content?.ctaComponet?.map((item, index) => (
                                <div key={`${item?.fields?.ctaText}-${index}`}>
                                    {item?.fields?.ctaText?.toLowerCase().includes('google play') && 
                                        <a href={item?.fields?.buttonLinkExternal} aria-label={item?.fields?.ctaText} target="_blank" rel="noreferrer">
                                            <GoogleIcon />
                                        </a>
                                    }
                                    {item?.fields?.ctaText?.toLowerCase().includes('app store') &&
                                        <a href={item?.fields?.buttonLinkExternal} aria-label={item?.fields?.ctaText} target="_blank" rel="noreferrer">
                                            <AppleIcon />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'image-left' &&
                    <div data-testid="featureImageLeft" className={`flex flex-col xl:flex-row items-center border border-neutral-50 rounded-2xl overflow-hidden ${bgColor}`}>
                         {(!isIconIncluded(content?.columnImage?.fields?.title) && !isIconIncluded(content?.columnImage?.fields?.description)) ? <div data-testid="image" aria-label={content?.columnImage?.fields?.title} className={`flex-1 self-stretch aspect-video bg-no-repeat bg-cover`} style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`}}></div> : 
                         <div className="pl-32 pt-32 lg:pl-48">
                            <Image imageUrl={content?.columnImage?.fields?.file?.url} altText={content?.columnImage?.fields?.title} width="56" />
                         </div>
                        }
                        <div className="flex-1">
                            <div className="p-32 lg:p-48">
                                <Headings content={content?.headline} variant="H3" classes={`${textColor}`} />
                                <Paragraph content={content?.bodyText} classes={`${textColor}`} />
                                <div className="flex flex-wrap flex-col gap-16 pt-24" data-testid="featureButtonComponent">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-right' &&
                    <div data-testid="featureImageRight" className={`flex flex-col xl:flex-row items-center border border-neutral-50 rounded-2xl overflow-hidden ${bgColor}`}>
                        {(!isIconIncluded(content?.columnImage?.fields?.title) && !isIconIncluded(content?.columnImage?.fields?.description)) ? <div aria-label={content?.columnImage?.file?.title} className={`flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover`} style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div> : 
                         <div className="pr-32 pt-32 lg:pr-48 xl:order-1">
                            <Image imageUrl={content?.columnImage?.fields?.file?.url} altText={content?.columnImage?.fields?.title} width="56" />
                         </div>
                        }
                        <div className="flex-1 xl:order-0">
                            <div className="p-32 lg:p-48">
                                <Headings content={content?.headline} variant="H3" classes={`${textColor}`} />
                                <Paragraph content={content?.bodyText} classes={`${textColor}`} />
                                <div className="flex flex-wrap flex-col gap-16 pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default FeatureBlock