import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import Headings from "../../Generic/Heading"
import Tag from "../../Generic/Tag"
import EventCardsCarousel from "./Carousel"
import Image from "../../Generic/Image"
import { RenderButtonEvents } from "../../Generic/Button/RenderButton"

interface Props {
    eventsData?: any
    content?: any
    cardBackground?: string
    columnLayout?: string
    carousel?: boolean
    buttonCarousel?: any
    buttonCards?: any
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param eventsData - Event cards data from the Contentful
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 */

const EventCards = ({ eventsData, cardBackground = 'white', columnLayout = '2', carousel, buttonCarousel, buttonCards }: Props) => {
    const bgCardColor = cardBackground === 'purple' ? 'bg-purple-10' : cardBackground === 'orange' ? 'bg-orange-10' : cardBackground === 'teal' ? 'bg-teal-10' : 'bg-white'
    const columns = columnLayout === '3' ? 'xl:w-[32%]' : columnLayout === '4' ? 'xl:w-[23.6%]' : 'xl:w-[48.5%]'
    const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`

    const handleClick = (e, link) => {
        e.stopPropagation()
        window.location.href = link
    }
  
  return (<>
        {carousel ?
            <EventCardsCarousel eventsData={eventsData} buttonCarousel={buttonCarousel} buttonCards={buttonCards} />
        :
            <div className="layoutSpacing">
                <div className={`w-full layout py-32 overflow-hidden`}>
                    <div className="flex flex-wrap justify-start gap-24">
                        {eventsData?.map((item, index) =>
                            <div key={`${item?.fields?.eventId}-${index}`} className={`flex border border-neutral-50 rounded-2xl overflow-hidden hover:border-purple-100 ${bgCardColor} ${layout}`}>
                                <div className="p-24 md:p-32">
                                    <div className="cursor-pointer" onClick={(e) => handleClick(e, `${buttonCards?.fields?.buttonLink}?eventId=${item?.fields?.eventId}`)}>
                                        <Headings variant="H4" content={`${item?.fields?.title}`} classes="text-purple-100 pb-16" />
                                    </div>
                                    <Paragraph content={item?.fields?.briefDescriptionForEventCards} classes="line-clamp-3" />
                                    {item?.fields?.fundTypeValue && 
                                        <div className="pt-32">
                                            <Tag name={item?.fields?.fundTypeValue} />
                                        </div>
                                    }
                                    <div className="py-32 grow">
                                        {item?.fields?.eventLocation && <div className="flex items-center gap-8 pb-16">
                                                <Image imageUrl={item?.fields?.locationIcon?.fields?.file?.url} altText={item?.fields?.locationIcon?.fields?.file?.title} width="20" />
                                                <Paragraph content={item?.fields?.eventLocation}/>
                                            </div>
                                        }
                                        {item?.fields?.startDate && <div className="flex items-center gap-8">
                                            <Image imageUrl={item?.fields?.eventCalendarIcon?.fields?.file?.url} altText={item?.fields?.eventCalendarIcon?.fields?.file?.title} width="20" />
                                            <Paragraph content={`${new Date(item?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(item?.fields?.startDate).toLocaleDateString('default', {month: 'long'})}`} />
                                        </div>
                                        }
                                    </div>
                                    {item?.fields?.capacity ?
                                        <RenderButtonEvents item={buttonCards} link={`${buttonCards?.fields?.buttonLink}?eventId=${item?.fields?.eventId}`} underline={false} />
                                    :
                                    <Paragraph content="Event capacity is full" classes="!text-danger-100" />
                                    }
                                </div>
                            </div>
                        )}
                        {eventsData?.length === 0 &&
                            <div>
                                <Paragraph content={"No events available. "}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default EventCards
