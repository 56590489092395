import * as React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useRef } from 'react';

interface Props {
  content?: any;
}

/**
 * SEOMeta Component
 * @param content - Content from the CMS.
 */
const SEOMeta = ({ content }: Props) => {
  const meta = useRef(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (meta.current === null) {
      meta.current = "loaded"
      setLoad(true)
    }
  }, [meta])
  return (<>
    {load &&
        <Helmet>
          <title>
            {content?.fields?.pageTitle
              ? content.fields.pageTitle
              : "Welcome to Emergency Services and State Super - ESSSuper"}
          </title>
          {/* Meta Description */}
          <meta
            name="description"
            content={content?.fields?.seo?.fields?.seodescription}
          />
          {/* Open Graph Tags */}
          <meta property="og:type" content="website" />
          {content?.fields?.seo?.fields?.canonicalUrl && (
            <link
              rel="canonical"
              href={content?.fields?.seo?.fields?.canonicalUrl}
            />
          )}

          {content?.fields?.seo?.fields?.keywords && (
            <meta
              name="keywords"
              content={content?.fields?.seo?.fields?.keywords}
            />
          )}
          {content?.fields?.seo?.fields?.seoTitle && (
            <meta
              property="og:title"
              content={content?.fields?.seo?.fields?.seoTitle}
            />
          )}
          {content?.fields?.seo?.fields?.seodescription && (
            <meta
              property="og:description"
              content={content?.fields?.seo?.fields?.seodescription}
            />
          )}
          {content?.fields?.seo?.fields?.featuredimage && (
            <meta
              property="og:image"
              content={
                content?.fields?.seo?.fields?.featuredimage?.fields?.file?.url
              }
            />
          )}
          {content?.fields?.seo?.fields?.hidePageFromSearchEngine ? (
            <meta name="robots" content={`${content?.fields?.seo?.fields?.hidePageFromSearchEngine ? 'noindex' : 'index'}, ${content?.fields?.seo?.fields?.excludeLinksFromSearchEngineRanking ? 'nofollow' : 'follow'}`} />
          ):(
            <meta name="robots" content={`index, follow`} />
          )}
        </Helmet>
      }
    </>
  );
};

export default SEOMeta;
