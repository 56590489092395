import * as React from "react";
import { useEffect, useState } from "react";
import TabSelector from "./TabSelector";
import EventCards from "./Cards";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { GetEventsDetailsAPI, GetTaxonomiesAPI, useContentAPI } from "../../../Hooks/useAPI";
import EventDetails from "./Detail";
import resolveResponse from "contentful-resolve-response";
import OutlineButton from "../../Generic/Button/Outline/Button";
import "./style.css"

interface Props {
  content?: any;
  background?: string;
  cardBackground?: string;
  columnLayout?: string;
  border?: boolean;
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const Events = ({
  content,
  background = "white"
}: Props) => {
  const { data } = useContentAPI("contentType", "eventsBlockComponent", "", false)
  const [eventDetailsData, setEventDetailsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const [eventsData, setEventsData] = useState([])
  const [unfilteredData, setUnFilteredData] = useState([])
  const [dbData, setDbData] = useState<any>("")
  const [taxonomyData, setTaxonomyData] = useState([])
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([])
  const bgColor =
    background === "purple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";

  const pageSize = 8
  const handlePagination = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setPageNumber(pageNumber + 1)
  }

  const filterData = (concepts: any, array: any) => {
    const filtered = array.map((evt) => {
      if (evt.metadata.concepts) {
        const index = evt.metadata.concepts.findIndex((cpt) => cpt.sys.id === concepts)
        if (index !== -1) {
          return evt
        }
      }
      return null
    }).filter((item) => item !== null)
    return filtered
  }

  const renderDBData = async () => {
    if (dbData === "") {
      const db = await GetEventsDetailsAPI()
      if (typeof dbData !== "string") { 
        if (db?.data.length > 0) {
          setDbData(db?.data)
        } else {
          setDbData([])
        }
      } else {
        setDbData([])
      }
    }
  }

  const renderTaxonomies = async () => {
    if (taxonomyData.length === 0) {
      const taxonomy = await GetTaxonomiesAPI('event')
      if (taxonomy?.data.length > 0) {
        setTaxonomyData(taxonomy?.data)
      }
    }
  }

  const checkCapacity = (eventId: string, location: string, capacity: number) => {
    const locationType = location.replace('-', '')
    const filteredData = (dbData.length > 0 && typeof dbData !== 'string') ? dbData.filter((item) => item?.EventID === `${eventId}` && item?.Type === locationType) : []
    if (filteredData.length === 0) {
      return true
    } else {
      const attendee = filteredData[0]?.Total_Attendee
      return attendee < capacity
    }
  }

  const getTotalAttendee = (eventId: string, location: string) => {
    const locationType = location.replace('-', '')
    const filteredData = (dbData.length > 0 && typeof dbData !== 'string') ? dbData.filter((item) => item?.EventID === `${eventId}` && item?.Type === locationType) : []
    if (filteredData.length === 0) {
      return 0
    } else {
      const attendee = filteredData[0]?.Total_Attendee
      return attendee
    }
  }

  const generateEventsData = async () => {
    const events = []

    const resolver = {
      items: data?.data?.items,
      includes: data?.data?.includes,
    };
    const response = resolveResponse(resolver);

    // filter only upcoming events and add capacity and total attendee
    response?.forEach((evt) => {
      const today = new Date()
      const startDate = new Date(evt?.fields?.startDate)
      if (startDate >= today) {
        const obj = {
          ...evt?.fields,
          capacity: checkCapacity(evt?.fields?.eventId, evt?.fields?.eventLocation, evt?.fields?.maximumNumberOfAttendees),
          totalAttendee: getTotalAttendee(evt?.fields?.eventId, evt?.fields?.eventLocation)
        }
        const obj1 = {
          fields: obj,
          sys: evt?.sys,
          metadata: evt?.metadata
        }
        events.push(obj1)
      }
    })

    sortByDate(events)

    setEventsData(events)
    setUnFilteredData(events)

    if (window.location.search.includes('eventId')) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const evtId = Number(urlParams?.get('eventId'))
      const details = events?.filter((item) => item?.fields?.eventId === evtId)
      if (details.length > 0)  {
        setEventDetailsData(details)
      } else {
        setEventDetailsData([{}])
      }
    }
    renderTaxonomies()
  }

  const sortByMonth = (arr) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    arr.sort(function(a, b) {
      return months.indexOf(a.prefLabel['en-US']) - months.indexOf(b.prefLabel['en-US']);
    });
  }

  const sortByDate = (arr) => {
    arr.sort(function(a, b) {
      const bDate: any = new Date(b.fields.startDate)
      const aDate: any = new Date(a.fields.startDate)
      return aDate - bDate
    });
  }

  const sortByFilters = (arr) => {
    arr.sort(function(a, b) {
      return a.hiddenLabels['en-US'][0]?.toLowerCase().localeCompare(b.hiddenLabels['en-US'][0]?.toLowerCase());
    });
  }

  useEffect(() => {
    if (!data || data === null) return
    renderDBData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!dbData || dbData === "") return
    generateEventsData()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbData])

  useEffect(() => {
    if (!taxonomyData || taxonomyData.length === 0) return

    const filterParent = taxonomyData?.filter((item) => item.broader.length === 0)
    const obj = []
    const selectedObj = []
    sortByFilters(filterParent)

    const filtersSelection = [content?.showEventAudienceFilter ? 'Event Audience' : '',
    content?.showEventDateFilter ? 'Event Date' : '',
    content?.showEventFundFilter ? 'Event Fund' : '',
    content?.showEventTopicFilter ? 'Event Topic' : '',
    content?.showEventTypeFilter ? 'Event Type' : '']
    
    filterParent.filter((filter) => filtersSelection.includes(filter.definition['en-US'])).forEach((item) => {
      const id = item?.sys?.id
      const name = item?.prefLabel['en-US']
      const filteredValues = taxonomyData?.filter((tax) => tax.broader.findIndex((broad) => broad.sys.id === id) !== -1)
      if (filteredValues.length > 0) {
        filteredValues.unshift({
          "sys": {
            "id": "All",
          },
          "prefLabel": {
            "en-US": "All"
          }
        })
        obj.push({
          parentId: id,
          parentName: name,
          childItems: filteredValues
        })
        selectedObj.push({
          parentId: id,
          parentName: name,
          childId: "All",
          childName: "All"
        })
      }
    })

    // Date Filter
    if (filtersSelection.includes('Event Date')) {
      const dateFilterValues = []

      eventsData?.forEach((dates) =>{
        const startDate = new Date(dates.fields?.startDate).toLocaleDateString('default', {month: 'long'})
        const obj = {
          "sys": {
            "id": startDate
          },
          "prefLabel": {
            "en-US": startDate
          }
        }
        if (dateFilterValues.findIndex((item) => item.sys.id === startDate) === -1) {
          dateFilterValues.push(obj)
        }
      })
      sortByMonth(dateFilterValues)

      dateFilterValues.unshift({
        "sys": {
          "id": "All",
        },
        "prefLabel": {
          "en-US": "All"
        }
      })
      obj.push({
        parentId: "date",
        parentName: "I'd like to attend in...",
        childItems: dateFilterValues
      })
      selectedObj.push({
        parentId: "date",
        parentName: "I'd like to attend in...",
        childId: "All",
        childName: "All"
      })
    }


    setFilters(obj)
    setSelectedFilters(selectedObj)
    setLoading(false)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxonomyData])

  useEffect(() => {
    if (!selectedFilters || selectedFilters.length === 0) return

    if (pageNumber !== 1) {
      setPageNumber(1)
    }

    // Filter other than date filter
    const childConceptIds = selectedFilters.filter((item) => item.parentId !== "date").map((filter) => filter.childId).filter((values) => values !== 'All')
    // const initialFilters = childConceptIds.every((value) => value === 'All')

    const dateIds = selectedFilters.filter((item) => item.parentId === "date").map((filter) => filter.childId).filter((values) => values !== 'All')
    
    let filteredData = []
    
    if (childConceptIds.length > 0) {
      childConceptIds.forEach((concepts, index) => {
        const array = index === 0 ? unfilteredData : filteredData
        const returnedData = filterData(concepts, array)
        if (returnedData.length > 0){
          filteredData = returnedData
        } else {
          filteredData = []
        }
      })

      if (filteredData.length > 0) {
        dateIds.forEach((concepts) => {
          if (concepts !== 'All') {
            const array = []
            filteredData.forEach((evt) => {
              // Filter Date
              const startDate = new Date(evt?.fields?.startDate).toLocaleDateString('default', {month: 'long'})
              if (concepts === startDate) {
                array.push(evt)
              }
            })
            filteredData = array
          }
        })
      }
  
    } else if (dateIds.length !== 0) {
      dateIds.forEach((concepts) => {
        if (concepts !== 'All') {
          const array = []
          unfilteredData.forEach((evt) => {
            // Filter Date
            const startDate = new Date(evt?.fields?.startDate).toLocaleDateString('default', {month: 'long'})
            if (concepts === startDate) {
              array.push(evt)
            }
          })
          filteredData = array
        }
      })
    } else {
      filteredData = unfilteredData
    }

    if (filteredData.length > 0) {
      setEventsData(filteredData)
    } else {
      setEventsData([])
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  useEffect(() => {
    const targetElement: any = document.querySelector('.backToEvents')
    const top = targetElement?.offsetTop
    window.scrollTo({top: top, behavior: 'smooth'})
    // const parentElement: any = document.querySelector('.events-page')
    // const parentTop = parentElement?.offsetTop
    // window.scrollTo({top: parentTop, behavior: 'smooth'})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetailsData])

  return (
    <>
      <div data-testid="event" className={`${bgColor} events-page sectionBottomSpacing`}>
        {(content?.headline || content?.description) && (
          <div className="layoutSpacing">
            <div className="w-full layout">
              <div className="w-full md:w-3/4">
                {content?.headline && (
                  <Headings
                    content={content?.headline}
                    variant="H2"
                    classes="text-purple-100"
                  />
                )}
                {content?.description && (
                  <Paragraph
                    content={content?.description}
                    classes="pb-16"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        
        {!loading && <>
          {eventDetailsData.length === 0 ? <>
            {filters.length > 0 &&
              <div className={`layoutSpacing ${content?.border ? 'border-b border-neutral-50 py-32' : 'pt-24'}`}>
                <div className="flex flex-wrap gap-24 w-full layout">
                  {filters?.map((item) => 
                    <div key={item.parentId} className="w-full md:w-auto">
                      <TabSelector content={item} selectedData={selectedFilters} setSelectedData={setSelectedFilters} />
                    </div>
                  )}
                </div>
              </div>
            }

            <EventCards
              eventsData={eventsData.slice(0, pageSize*pageNumber)}
              carousel={content?.carousel}
              data-testid="eventCardss"
              buttonCarousel={content?.seeAllEvents}
              buttonCards={content?.registerButtonEventCards}
            />

            {!content?.carousel && (eventsData.length > pageSize*pageNumber) &&
              <div className="flex justify-center pt-32" onClick={(e) => handlePagination(e)}>
                <OutlineButton name="Load more" variant="dark" buttonType="submit" classes="cursor-pointer" />
              </div>
            }
            </>
            :
            <div data-testid="eventDetails" className={`${bgColor} events-details-page sectionTopSpacing`}>
              <EventDetails content={content?.features} eventDetailsData={eventDetailsData} eventsData={eventsData} data-testid="eventDetails" buttonDetails={content?.registerButtonEventDetails} buttonCards={content?.registerButtonEventCards} />
            </div>
          }
        </>}

        {loading &&
          <div className="layoutSpacing sectionTopSpacing">
            <div className="w-full layout">
              <Headings
                variant="H3"
                content="Loading Events ..."
                classes="text-purple-100"
              />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Events;
