import * as React from "react"
import Paragraph from "../../Generic/Paragraph"

interface Props {
    content: any
    fullWidth?: boolean
    border?: boolean
    classes?: string
}

/**
 * Disclaimer Component
 * @param content - Content from the CMS.
 * @param fullWidth (optional) - Full width. Expected values: true | false, Default: true
 * @param border (optional) - Top border for the component. Expected values: true | false, Default:  false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */
const DisclaimerBlock = ({content, fullWidth = false, border, classes}: Props) => {
    content = content.length > 0 ? content?.filter((item) => item?.sys?.contentType?.sys?.id === "disclaimer") : content
    return (<>
        {(content || content.length > 0) &&
            <div data-testid="disclaimerBlock" className={`${fullWidth ?  '' : 'layoutSpacing'} w-100 ${border ? 'border-neutral-50 border-t' : ''}`}>
                <div className={`disclaimer w-100 ${border ? 'sectionSpacing' : ''} ${fullWidth ?  '' : 'layout'}`}>
                    {content.length > 0 && content?.map((item, index) => 
                        <div key={`${content?.sys?.id}-${index}`}>
                            <Paragraph content={item?.fields?.disclaimer} classes={`${classes ? classes : 'text-neutral-90 !text-base'}`} />
                        </div>
                    )}
                    {!content.length && <Paragraph content={content?.fields?.disclaimer} classes={`${classes ? classes : 'text-neutral-90'}`} />
                    }
                </div>
            </div>
        }
        </>
    )
}

export default DisclaimerBlock