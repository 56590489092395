import * as React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface Props {
  content?: any;
  classes?: any;
}

const Richtext = ({ content, classes }: Props) => {
  const options = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <span key={index} className="block h-24" />, textSegment];
      }, []);
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri) {
          const target = node.data.uri.startsWith('/') ? '_self' : '_blank';
          return <a href={node.data.uri} target={target}>{node.content[0].value}</a>;
        } else {
          return <a href={`${window.location.href}`}>{node.content[0].value}</a>;
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        if (node.data.target.fields) {
          const { slug } = node.data.target.fields;
          return <a href={`/${slug}`}>{node.content[0].value}</a>;
        } else {
          return <a href={`${window.location.href}`}>{node.content[0].value}</a>;
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        if (node.data.target.fields) {
          const { title, file } = node.data.target.fields;
          return (
            <a href={`/${file?.url}`} target="_blank" rel="noreferrer">
              {title}
            </a>
          );
        }
      },
      [BLOCKS.TABLE]: (node, children) => (
        <div className="tableWrapper">
          <table>
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
    },
  };

  return (
    <>
      <div
        data-testid="paragraph"
        className={`text-container text-md ${classes ? classes : ""}`}
      >
        {documentToReactComponents(content, options)}
      </div>
    </>
  );
};
export default Richtext;
