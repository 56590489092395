import * as React from "react";
import { useEffect, useState } from "react";
import Listing from "../Listing";

interface Props {
  content?: any;
}

/**
 * Podcast Component
 * @param content - Content from the CMS.
 */
const Podcast = ({
  content
}: Props) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const list = []
    content?.features?.forEach((item) => {list.push({
        tag: null,
        iconUrl: null,
        imageUrl: null,
        headLine: item?.fields?.title ? item?.fields?.title : null,
        contentDescription: item?.fields?.description ? item?.fields?.description : null,
        contentCtaLinkToPage: null,
        publishDate: item?.fields?.publishedDate ? item?.fields?.publishedDate : null,
        timeToRead: null,
        videoPodcastUrl: item?.fields?.videoPodcastUrl ? item?.fields?.videoPodcastUrl : null,
        buzzsproutId: item?.fields?.buzzsproutId ? item?.fields?.buzzsproutId : null
      })
    })
    setData(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>
    <div className={`layoutSpacing`}>
      <div className={`layout`}>
        {data?.map((item, index) => 
          <Listing key={`${item?.headLine}-${index}`} content={item} />
        )}
      </div>
    </div>
  </>)
};

export default Podcast;
