import * as React from "react"
import Headings from "../../Generic/Heading"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"
import './style.css'
import { useEffect, useState } from "react"

interface Props {
    content?: any
}
/**
 * Quick Links Component
 */
const QuickLinksBlock = ({content}: Props) => {
    const [firstCol, setFirstCol] = useState([])
    const [secondCol, setSecondCol] = useState([])
    const [thirdCol, setThirdCol] = useState([])
    const { isMobile } = useDeviceDetector()

    const toggleQuickLinks = (e: any) => {
        e.stopPropagation()
        if(isMobile) {
            const parentEle = e.target.closest('.container')
            parentEle.classList.toggle('active')
        }
    }

    const toggleInvestmentsQuickLinks = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.container')
        parentEle.classList.toggle('active')
    }

    const generateLink = (text, link) => {
        return `<a href=${link}>${text}</a>`
    }

    useEffect(() => {
        if (!content?.features) return
        const firstCol = content?.features?.map((item, index) => {
            if (index % 3 === 0) {
                return item
            }
            return undefined
        }).filter((item) => item !== undefined)
        setFirstCol(firstCol)
        const secondCol = content?.features?.map((item, index) => {
            if (index % 3 === 1) {
                return item
            }
            return undefined
        }).filter((item) => item !== undefined)
        setSecondCol(secondCol)
        const thirdCol = content?.features?.map((item, index) => {
            if (index % 3 === 2) {
                return item
            }
            return undefined
        }).filter((item) => item !== undefined)
        setThirdCol(thirdCol)
    }, [content])

    return (<>
        <div className="layoutSpacing">
            <div data-testid="quickLinks" className="w-100 sectionBottomSpacing layout">
                {!content?.investmentsDesign &&
                    <div className={`flex flex-wrap quicklinks ${isMobile ? 'mobile' : ''} gap-24`}>
                        {content?.features?.map((item, index) =>
                            <div key={`${item?.fields?.headline}-${index}`} className="container flex flex-col w-full sm:w-[calc(50%-12px)] lg:w-[calc(33%-18px)] xl:w-[calc(25%-18px)]">
                                <div className="title flex justify-between items-center pb-24 mb-24 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleQuickLinks(e)}}>
                                    {item?.fields?.link && <Headings content={generateLink(item?.fields?.headline, item?.fields?.link)} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                    {!item?.fields?.link && <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                    {isMobile && item?.fields?.linksgroup?.length > 0 && (<div key={`arrow-icon-${index}`}>
                                        <ArrowUpIcon />
                                        <ArrowDownIcon />
                                        </div>)
                                    }
                                </div>
                                <div className={`list flex flex-col items-start w-100 mb-24 gap-24`}>
                                    {item?.fields?.linksgroup?.map((links) =>
                                        <div key={`${links?.fields?.linkName}-${index}`}>
                                            <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.externalLink ? links?.fields?.externalLink : links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={true} classes="mb-8 sub-links" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                }
                {content?.investmentsDesign &&
                    <div className={`flex flex-wrap justify-between investmentsquicklinks ${isMobile ? 'mobile' : ''}`}>
                        <div className="w-full sm:flex lg:block justify-between lg:w-[32%]">
                            {firstCol?.map((item, index) => 
                                <div key={`${item?.fields?.headline}-${index}`} className={`container flex flex-col w-full ${firstCol.length > 1 ? 'sm:w-[48%]' : ''} lg:w-full`}>
                                    <div className="title flex justify-between items-center pb-24 mb-24 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleInvestmentsQuickLinks(e)}}>
                                        {item?.fields?.link && <Headings content={generateLink(item?.fields?.headline, item?.fields?.link)} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {!item?.fields?.link && <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {item?.fields?.linksgroup?.length > 0 && (<div key={`arrow-icon-${index}`}>
                                            <ArrowUpIcon />
                                            <ArrowDownIcon />
                                            </div>)
                                        }
                                    </div>
                                    <div className={`list flex flex-col items-start w-100 mb-24 gap-24`}>
                                        {item?.fields?.linksgroup?.map((links) =>
                                            <div key={`${links?.fields?.linkName}-${index}`}>
                                                <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.externalLink ? links?.fields?.externalLink : links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={true} classes="mb-8 sub-links" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="w-full sm:flex lg:block justify-between lg:w-[32%]">
                            {secondCol?.map((item, index) => 
                                <div key={`${item?.fields?.headline}-${index}`} className={`container flex flex-col w-full ${secondCol.length > 1 ? 'sm:w-[48%]' : ''} lg:w-full`}>
                                    <div className="title flex justify-between items-center pb-24 mb-24 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleInvestmentsQuickLinks(e)}}>
                                        {item?.fields?.link && <Headings content={generateLink(item?.fields?.headline, item?.fields?.link)} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {!item?.fields?.link && <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {item?.fields?.linksgroup?.length > 0 && (<div key={`arrow-icon-${index}`}>
                                            <ArrowUpIcon />
                                            <ArrowDownIcon />
                                            </div>)
                                        }
                                    </div>
                                    <div className={`list flex flex-col items-start w-100 mb-24 gap-24`}>
                                        {item?.fields?.linksgroup?.map((links) =>
                                            <div key={`${links?.fields?.linkName}-${index}`}>
                                                <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.externalLink ? links?.fields?.externalLink : links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={true} classes="mb-8 sub-links" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`w-full sm:flex lg:block justify-between lg:w-[32%]`}>
                            {thirdCol?.map((item, index) =>
                                <div key={`${item?.fields?.headline}-${index}`} className={`container flex flex-col w-full ${thirdCol.length > 1 ? 'sm:w-[48%]' : ''} lg:w-full`}>
                                    <div className="title flex justify-between items-center pb-24 mb-24 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleInvestmentsQuickLinks(e)}}>
                                        {item?.fields?.link && <Headings content={generateLink(item?.fields?.headline, item?.fields?.link)} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {!item?.fields?.link && <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12 !pb-0" />}
                                        {item?.fields?.linksgroup?.length > 0 && (<div key={`arrow-icon-${index}`}>
                                            <ArrowUpIcon />
                                            <ArrowDownIcon />
                                            </div>)
                                        }
                                    </div>
                                    <div className={`list flex flex-col items-start w-100 mb-24 gap-24`}>
                                        {item?.fields?.linksgroup?.map((links) =>
                                            <div key={`${links?.fields?.linkName}-${index}`}>
                                                <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.externalLink ? links?.fields?.externalLink : links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={true} classes="mb-8 sub-links" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default QuickLinksBlock