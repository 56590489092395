import * as React from "react";
import Paragraph from "../../Generic/Paragraph";
import Headings from "../../Generic/Heading";
import Image from "../../Generic/Image";
import { ButtonWrapper, RenderButton } from "../../Generic/Button/RenderButton";
import Video from "../../Generic/Video";

interface Props {
  content?: any;
  background?: string;
  cardBackground?: string;
  columnLayout?: string;
  border?: boolean;
  marginBottom?: boolean;
  isArticle?: boolean
}

/**
 * Card Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "lightPurple" | "orange", Default: "white"
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "lightPurple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4", Default: "2"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 * @param marginBottom (optional) - Bottom margin of the Card. Expected values: true | false, Default: true
 */
const Card = ({
  content,
  background = "white",
  cardBackground = "white",
  columnLayout = "2",
  border = false,
  marginBottom = true,
  isArticle
}: Props) => {
  background = background.toLowerCase();
  cardBackground = cardBackground.toLowerCase();
  const bgColor =
    background === "lightpurple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";
  const bgCardColor =
    cardBackground === "lightpurple"
      ? "bg-purple-10"
      : cardBackground === "orange"
      ? "bg-orange-10"
      : cardBackground === "teal"
      ? "bg-teal-10"
      : "bg-white";
  const columns =
    columnLayout === "3"
      ? "xl:w-[32%]"
      : columnLayout === "4"
      ? "xl:w-[23.6%]"
      : "xl:w-[49%]";
  const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`;

  const isIconIncluded = (str) => {
    return str && str.toLowerCase().includes("icon");
  };

  return (
    <>
      <div className={`layoutSpacing ${bgColor}`}>
        <div
          className={`w-full ${
            bgColor !== "bg-white" ? "sectionSpacing" : "sectionBottomSpacing"
          } 
        ${
          bgColor !== "bg-white" && marginBottom ? "sectionBottomMargin" : ""
        } layout`}
        >
          {(content?.headline || content?.description) && (
            <div className={`w-full ${isArticle ? '' : 'lg:w-60'} pb-24`}>
              {content?.headline && (
                <Headings
                  content={content?.headline}
                  variant="H2"
                  classes="text-purple-100"
                />
              )}
              {content?.subheadline && (
                <Headings
                  content={content?.subheadline}
                  variant="H3"
                  classes="text-purple-100"
                />
              )}
              {content?.description && (
                <Paragraph content={content?.description} classes="pb-16" />
              )}
            </div>
          )}
          <div className={`flex flex-wrap justify-start gap-16 ${isArticle ? '2xl:gap-16' : '2xl:gap-24'} mb-24`}>
            {content?.features?.map((item, index) => (<div
                  data-testid="card"
                  key={`${item?.fields?.headline}-${index}`}
                  className={`flex flex-col ${
                    border ? "border border-neutral-50" : ""
                  } rounded-2xl overflow-hidden hover:border-purple-100 ${bgCardColor} w-full ${
                    content?.features.length > 1 ? layout : ""
                  }`}
                >
              {item?.sys?.contentType?.sys?.id ===
                "blockInfoFeatureComponent" && 
                <div>
                  {item?.fields?.contentImage?.fields?.file?.url &&
                      !isIconIncluded(
                        item?.fields?.contentImage?.fields?.title
                      ) &&
                      !isIconIncluded(
                        item?.fields?.contentImage?.fields?.description
                      ) && (
                        <div data-testid="image">
                          <Image
                            width="100%"
                            imageUrl={
                              item?.fields?.contentImage?.fields?.file?.url
                            }
                            altText={item?.fields?.contentImage?.fields?.title}
                          />
                        </div>
                      )}
                    {item?.fields?.contentImage?.fields?.file?.url &&
                      (isIconIncluded(
                        item?.fields?.contentImage?.fields?.title
                      ) ||
                        isIconIncluded(
                          item?.fields?.contentImage?.fields?.description
                        )) && (
                        <div
                          data-testid="icon"
                          className="p-24 md:p-32 pb-0 md:pb-0"
                        >
                          <Image
                            imageUrl={
                              item?.fields?.contentImage?.fields?.file?.url
                            }
                            altText={item?.fields?.contentImage?.fields?.title}
                            width="56"
                          />
                        </div>
                      )}
                    {item?.fields?.headline &&
                      item?.fields?.headline !== null && (
                        <div
                          data-testid="headingsCard"
                          className={`${
                            !item?.fields?.contentDescription ||
                            item?.fields?.contentDescription === null
                              ? "flex-grow"
                              : ""
                          } p-24 md:p-32 pb-0 md:pb-0`}
                        >
                          <Headings
                            variant="H4"
                            content={item?.fields?.headline}
                            classes={`text-purple-100`}
                          />
                        </div>
                      )}
                    {item?.fields?.contentDescription &&
                      item?.fields?.contentDescription !== null && (
                        <>
                          <div
                            data-testid="paragraphCard"
                            className={`flex-grow px-24 md:px-32 pb-16 md:pb-24 ${!item?.fields?.headline ? 'pt-16 md:pt-32' : ''}`}
                          >
                            <Paragraph
                              content={item?.fields?.contentDescription}
                              classes=""
                            />
                            {/* line-clamp-3 */}
                          </div>
                        </>
                      )}
                    {item?.fields?.video && 
                      <div className="px-24 md:px-32 pb-16 md:pb-32">
                        <Video videoUrl={item?.fields?.video} classes={`${item?.fields?.caption ? 'mb-16' : ''}`} caption={item?.fields?.caption} />
                      </div>
                    }
                    {item?.fields?.contentCtaLinkToPage &&
                      item?.fields?.contentCtaLinkToPage !== null && (
                        <div
                          data-testid="textButtonWithIcon"
                          className={`px-24 md:px-32 pb-24 md:pb-32 ${!item?.fields?.contentDescription ? 'pt-32' : ''}`}
                        >
                          <RenderButton
                            item={item?.fields?.contentCtaLinkToPage[0]}
                          />
                        </div>
                      )}
                </div>
            }</div>
            ))}
            {content?.assets?.map((item, index) => (
              <div key={`${item?.fields?.title}-${index}`}>
                {item?.fields?.file?.contentType.includes("image") && (
                  <Image
                    imageUrl={item?.fields?.file?.url}
                    altText={item?.fields?.description}
                    className={`mb-16 ${(isIconIncluded(item?.fields?.title) && isIconIncluded(item?.fields?.description)) ? 'w-56' : ''}`}
                  />  
                )}
                {item?.fields?.file?.contentType.includes("video") && (
                  <Video videoUrl={item?.fields?.file?.url} />
                )}
              </div>
            ))}
          </div>
          <div className={`flex justify-center ${content.spacingAboveButtons ? 'mt-24' : ''}`}>
            <ButtonWrapper content={content} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
