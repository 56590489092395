import * as React from "react";
import Paragraph from "../../Generic/Paragraph";
import Image from "../../Generic/Image";
import './style.css'

interface Props {
    content?: any
    isArticle?: boolean
}

/**
 * TwoColumnArticle Component
 * @param content - Content from the CMS.
 * @param isArticle (optional) - To render article layout
 */

const TwoColumnArticle = ({
  content,
  isArticle
}: Props) => {
  
  return (
    <>
      <div className={`layoutSpacing twocol`}>
        <div className={`w-full layout sectionBottomSpacing`}>
            <div className={`flex flex-wrap flex-col md:flex-row justify-between w-100 ${isArticle ? '' : 'md:w-75 xl:w-60'}`}>
                <div className={`${content?.imagePosition ? 'order-2 mt-24 md:pl-24 md:mt-0' : 'order-1 mb-24 md:pr-24 md:mb-0' } md:w-[49%]`}>
                    <Paragraph content={content?.description} />
                </div>
                <div className={`${content?.imagePosition ? 'order-1 mb-24 md:pr-24 md:mb-0' : 'order-2 mt-24 md:pl-24 md:mt-0' } md:w-[49%]`}>
                    <Image
                        imageUrl={content?.image?.fields?.file?.url}
                        altText={content?.image?.fields?.description}
                        caption={content?.captionText}
                    />
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default TwoColumnArticle;
