import * as React from "react"
import {ReactComponent as CalculatorIcon} from '../../../Assets/calculate.svg'
import TextButtonWithIcon from "../Button/Text/ButtonWithIcon"
import Paragraph from "../Paragraph"
import Image from "../Image"
import { RenderButton } from "../Button/RenderButton"

interface Props {
    content: any
    count?: number
}

/**
 * IconList Component
 * @param content - name of the tag.
 */

const IconList = ({content, count}: Props) => {
    return (<>
            <div data-testid="iconList" className={`py-32 ${count === 1 ? '' : 'border-b border-bg-neutral-50'}`}>
                <div className="flex items-center gap-32">
                    {content?.fields?.icon !== null &&
                        <Image
                            imageUrl={content?.fields?.icon?.fields?.file?.url}
                            altText={content?.fields?.icon?.fields?.file?.description}
                            width="56"
                        />
                    }
                    <div className="flex flex-col gap-12">
                        <RenderButton item={content?.fields?.buttonReference} />
                        {content?.fields?.description !== null && (
                            <Paragraph
                                content={content?.fields?.description}
                                classes="text-black !pb-0 !mb-0"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default IconList