import * as React from "react";
import IconList from "../../Generic/IconList";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { ButtonWrapper } from "../../Generic/Button/RenderButton";
import Video from "../../Generic/Video";
import Image from "../../Generic/Image";

interface Props {
  content?: any;
  background?: string;
  columnLayout?: string;
  marginBottom?: boolean;
  isArticle?: boolean
}

/**
 * IconListBlock Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "lightPurple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4", Default: "2"
 * @param marginBottom (optional) - Bottom margin of the Card. Expected values: true | false, Default: true
 */
const IconListBlock = ({
  content,
  background = "white",
  columnLayout = "2",
  marginBottom = true,
  isArticle
}: Props) => {
  background = background.toLowerCase();
  const bgColor =
    background === "lightpurple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";
  const columns =
    columnLayout === "3"
      ? "xl:w-[32%]"
      : columnLayout === "4"
      ? "xl:w-[23.6%]"
      : "xl:w-[49%]";

  const isIconIncluded = (str) => {
    return str && str.toLowerCase().includes("icon");
  };
  return (
    <>
      <div className={`layoutSpacing ${bgColor}`}>
        <div
          className={`w-full ${
            bgColor !== "bg-white" ? "sectionSpacing" : "sectionBottomSpacing"
          } 
        ${
          bgColor !== "bg-white" && marginBottom ? "sectionBottomMargin" : ""
        } layout`}
        >
          {(content?.headline || content?.description) && (
            <div className={`w-full ${isArticle ? '' : 'lg:w-60'}`}>
              {content?.headline && (
                <Headings
                  content={content?.headline}
                  variant="H2"
                  classes="text-purple-100"
                />
              )}
              {content?.subheadline && (
                <Headings
                  content={content?.subheadline}
                  variant="H4"
                  classes="text-purple-100"
                />
              )}
              {content?.description && (
                <Paragraph content={content?.description} classes="pb-16" />
              )}
            </div>
          )}
          <div className="flex flex-wrap justify-start gap-16 2xl:gap-24">
            {content?.features?.map((item) =>
              <div key={item?.sys?.id} className="">
                <IconList content={item} count={content?.features.length} />
              </div>
            )}
            {content?.assets?.map((item, index) => (
              <div key={`${item?.fields?.title}-${index}`}>
                {item?.fields?.file?.contentType.includes("image") && (
                  <Image
                    imageUrl={item?.fields?.file?.url}
                    altText={item?.fields?.description}
                    className={`mb-16 ${(isIconIncluded(item?.fields?.title) && isIconIncluded(item?.fields?.description)) ? 'w-56' : ''}`}
                  />  
                )}
                {item?.fields?.file?.contentType.includes("video") && (
                  <Video videoUrl={item?.fields?.file?.url} />
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-24">
            <ButtonWrapper content={content} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
};

export default IconListBlock;
