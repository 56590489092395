import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import Breadcrumbs from "../Breadcrumbs";
import { RenderButton } from "../../Generic/Button/RenderButton";

interface Props {
  content?: any;
  background?: string;
  breadcrumb?: any;
  isArticle?: boolean
}

/**
 * Page Banner Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "lightPurple" | "orange", Default: "purple"
 * @param isArticle (optional) - To set article page layout
 */
const PageBanner = ({
  content,
  background = "lightPurple",
  breadcrumb,
  isArticle
}: Props) => {
  background = background.toLowerCase();
  const bgColor =
    background === "purple"
      ? "bg-purple-100"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : background === "lightpurple"
      ? "bg-purple-10"
      : "bg-white";

  const formatDate = (publishDate) => {
    return `${new Date(publishDate).toLocaleDateString("default", {
      day: "numeric",
    })} ${new Date(publishDate).toLocaleDateString("default", {
      month: "long",
    })} ${new Date(publishDate).toLocaleDateString("default", {
      year: "numeric",
    })}`;
  };

  return (
    <>
      <div
        data-testid="pageBanner"
        className={`layoutSpacing w-100 ${
          background === "white" ? "sectionTopSpacing" : "sectionSpacing"
        } ${bgColor} sectionBottomMargin`}
      >
        <div className="layout">
          {breadcrumb && <Breadcrumbs content={breadcrumb} />}
          <div className={`flex flex-col w-100 ${isArticle ? '' : 'md:w-75 xl:w-60'}`}>
            {content?.headlineWithContent && (
              <>
                {content?.headlineWithContent[0]?.fields?.headline && (
                  <Headings
                    variant="H1"
                    content={content?.headlineWithContent[0]?.fields?.headline}
                    classes="text-purple-100"
                  />
                )}
                {content?.headlineWithContent[0]?.fields?.subheadline && (
                  <Headings
                    variant="H3"
                    content={
                      content?.headlineWithContent[0]?.fields?.subheadline
                    }
                    classes="text-purple-100"
                  />
                )}
                <Paragraph
                  content={
                    content?.headlineWithContent[0]?.fields?.description ||
                    content?.headlineWithContent[0]?.fields?.richText
                  }
                  classes="pt-16 !text-xl"
                />
                {content?.publicationDate &&
                  content?.publicationDate !== null && (
                    <div className={`flex gap-32`}>
                      <Paragraph
                        content={`${formatDate(content?.publicationDate)}`}
                        classes="text-neutral-90 pb-16 pt-16"
                      />
                      <Paragraph
                        content={`<span style="display:list-item;">${content?.timeToReadText}</span>`}
                        classes="text-neutral-90 pb-16 pt-16"
                      />
                    </div>
                  )}
                {content?.headlineWithContent[0]?.fields?.button && (
                  <div className="pt-24">
                    <RenderButton
                      item={content?.headlineWithContent[0]?.fields?.button[0]}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageBanner;
