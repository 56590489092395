import * as React from "react";
import { useEffect, useState } from "react";
import { useContentAPI } from "../../../Hooks/useAPI";
import TabSelector from "./TabSelector";
import './style.css'
import Paragraph from "../../Generic/Paragraph";
import Headings from "../../Generic/Heading";

interface Props {
  content?: any
}

/**
 * Performances Component
 */

const Performances = ({content}: Props) => {
  const { data } = useContentAPI("contentType", "performanceSection", "", false)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [returns, setReturns] = useState([])
  const [selectedReturns, setSelectedReturns] = useState("")

  const getFiltersByFields = (fieldName: string) => {
    const array = []
    data?.data?.items?.forEach((item) =>  {
      const prod = item?.fields[fieldName]
      if (prod) {
        if (!array.includes(prod)) {
          array.push(prod)
        }
      }
    })
    return array
  }

   const toggleTableShadow = () => {
    const tables = document.getElementsByTagName('table')
    for (let i = 0; i < tables.length; i++) {
      const tbody = tables[i].getElementsByTagName('tbody')[0].clientWidth
      const parent = tables[i].parentElement
      const eq = tbody === tables[i].parentElement.clientWidth;
      const hs = tbody > tables[i].parentElement.clientWidth;
      if (eq) {
        tables[i].classList.remove('scroll')
        parent.classList.remove('maxct')
      } else {
        if (hs) {
          tables[i].classList.add('scroll')
          parent.classList.remove('maxct')
        } else {
          tables[i].classList.remove('scroll')
          parent.classList.add('maxct')
        }
      }
    }
  }

  useEffect(() => {
    if (!data || data === null) return

    const prods = getFiltersByFields('products')
    const sortedProducts = prods.sort()
    setProducts(sortedProducts)
    setSelectedProduct(sortedProducts[0])

    const returnsData = getFiltersByFields('returnsType')
    setReturns(returnsData)
    setSelectedReturns(returnsData[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (selectedProduct === "" || selectedReturns === "") return

    const filteredArray = data?.data?.items?.filter((item) =>  item.fields.products === selectedProduct && item.fields.returnsType === selectedReturns)
    setFilteredData(filteredArray)

    toggleTableShadow()
    window.addEventListener('resize', toggleTableShadow);
    return () => {
      window.removeEventListener('resize', toggleTableShadow);
    };

  }, [selectedProduct, selectedReturns])

  useEffect(() => {
    if (filteredData.length === 0) return

    toggleTableShadow()
    window.addEventListener('resize', toggleTableShadow);
    return () => {
      window.removeEventListener('resize', toggleTableShadow);
    };

  }, [filteredData])

  return (
    <div data-testid="performance" className="performance layoutSpacing">
      <div className="layout w-full sectionBottomSpacing">
        <div className="flex flex-col gap-40">
          <Headings
            variant="H3"
            content={content?.accountTypeHeadline}
            classes="text-purple-100 !pb-0"
          />
          <TabSelector content={products} setSelectedData={setSelectedProduct} selectedData={selectedProduct} />
          <Headings
            variant="H3"
            content={content?.returnsTypesHeadline}
            classes="text-purple-100 !pb-0"
          />
          <TabSelector content={returns} setSelectedData={setSelectedReturns} selectedData={selectedReturns} />
          {filteredData?.map((item, index) =>
            <div key={`${item?.fields?.componentName}-${index}`}>
              <Paragraph content={item?.fields?.content} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Performances;
