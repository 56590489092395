import * as React from "react";
import Headings from "../../Generic/Heading";
import Tab from "./Tab";

interface Props {
  content?: any;
  border?: boolean
  isArticle?: boolean
}

/**
 * Tabs Component
 * @param content - Content from the CMS.
 */
const Tabs = ({
  content, border, isArticle
}: Props) => {
  return (
    <>
      <div className={`layoutSpacing`}>
        <div className={`w-full layout`}>
          {content?.headline && (
            <div className={`w-full ${isArticle ? '' : 'lg:w-60'} pb-24`}>
              {content?.headline && (
                <Headings
                  content={content?.headline}
                  variant="H2"
                  classes="text-purple-100"
                />
              )}
            </div>
          )}
          {content?.features?.map((item, index) => 
            <div key={`${item?.fields?.headline}-${index}`}>
              <Tab content={item?.fields} border={border} isArticle={isArticle} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Tabs;
